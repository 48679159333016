/*@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic|Roboto:300,400,700,400italic,700italic|Raleway:600);*/
/*@import url(https://fonts.googleapis.com/css?family=Candal&text=��);*/
@-webkit-viewport{
    width: device-width;
    zoom: 1;
}

@-moz-viewport{
    width: device-width;
    zoom: 1;
}

@-ms-viewport{
    width: device-width;
    zoom: 1;
}

@-o-viewport{
    width: device-width;
    zoom: 1;
}

@viewport{
    width: device-width;
    zoom: 1;
}

@page {
    size: auto;
    margin: 1cm 1cm 1cm 1cm;
}

@keyframes -moz-zoom {
    from {
        transform: scale(1);
    }

    50% {
        opacity: 1;
    }

    to {
        transform: scale(4);
        opacity: 0;
    }
}

@keyframes -webkit-zoom {
    from {
        -webkit-transform: scale(1);
    }

    50% {
        opacity: 1;
    }

    to {
        -webkit-transform: scale(4);
        opacity: 0;
    }
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    50% {
        opacity: 1;
    }

    to {
        transform: scale(4);
        opacity: 0;
    }
}

@keyframes -moz-shake {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes -webkit-shake {
    0% {
        -webkit-transform: translateY(0);
    }

    25% {
        -webkit-transform: translateY(10px);
    }

    50% {
        -webkit-transform: translateY(0);
    }

    75% {
        -webkit-transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
    }
}

@keyframes shake {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes -moz-shake2 {
    0%   { transform: translate(2px, 1px)   rotate(0deg); }
    10%  { transform: translate(-1px, -2px) rotate(-1deg); }
    20%  { transform: translate(-3px, 0px)  rotate(1deg); }
    30%  { transform: translate(0px, 2px)   rotate(0deg); }
    40%  { transform: translate(1px, -1px)  rotate(1deg); }
    50%  { transform: translate(-1px, 2px)  rotate(-1deg); }
    60%  { transform: translate(-3px, 1px)  rotate(0deg); }
    70%  { transform: translate(2px, 1px)   rotate(-1deg); }
    80%  { transform: translate(-1px, -1px) rotate(1deg); }
    90%  { transform: translate(2px, 2px)   rotate(0deg); }
    100% { transform: translate(1px, -2px)  rotate(-1deg); }
}

@keyframes -webkit-shake2 {
    0%   { -webkit-transform: translate(2px, 1px)   rotate(0deg); }
    10%  { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
    20%  { -webkit-transform: translate(-3px, 0px)  rotate(1deg); }
    30%  { -webkit-transform: translate(0px, 2px)   rotate(0deg); }
    40%  { -webkit-transform: translate(1px, -1px)  rotate(1deg); }
    50%  { -webkit-transform: translate(-1px, 2px)  rotate(-1deg); }
    60%  { -webkit-transform: translate(-3px, 1px)  rotate(0deg); }
    70%  { -webkit-transform: translate(2px, 1px)   rotate(-1deg); }
    80%  { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
    90%  { -webkit-transform: translate(2px, 2px)   rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px)  rotate(-1deg); }
}

@keyframes shake2 {
    0%   { transform: translate(2px, 1px)   rotate(0deg); }
    10%  { transform: translate(-1px, -2px) rotate(-1deg); }
    20%  { transform: translate(-3px, 0px)  rotate(1deg); }
    30%  { transform: translate(0px, 2px)   rotate(0deg); }
    40%  { transform: translate(1px, -1px)  rotate(1deg); }
    50%  { transform: translate(-1px, 2px)  rotate(-1deg); }
    60%  { transform: translate(-3px, 1px)  rotate(0deg); }
    70%  { transform: translate(2px, 1px)   rotate(-1deg); }
    80%  { transform: translate(-1px, -1px) rotate(1deg); }
    90%  { transform: translate(2px, 2px)   rotate(0deg); }
    100% { transform: translate(1px, -2px)  rotate(-1deg); }
}

@keyframes -moz-slidein {
    from {
        left: -215px;
    }

    to {
        left: 0;
    }
}

@keyframes -webkit-slidein {
    from {
        left: -215px;
    }

    to {
        left: 0;
    }
}

@keyframes slidein {
    from {
        left: -215px;
    }

    to {
        left: 0;
    }
}

@keyframes -moz-slideout {
    from {
        left: 0px;
    }

    to {
        left: -215px;
    }
}

@keyframes -webkit-slideout {
    from {
        left: 0px;
    }

    to {
        left: -215px;
    }
}

@keyframes slideout {
    from {
        left: 0px;
    }

    to {
        left: -215px;
    }
}

@keyframes -moz-fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes -webkit-fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* COULEURS */
.bleu_fonce              {color: #18425F;}
.bleu_electrique         {color: #0D85D2;}
.bleu_electrique_clair   {color: #0F9BF2;}
.bleu_electrique_clair2  {color: #08B0EF;}
.bleu_blanc              {color: #D9E9F5;}
.bleu_blanc2             {color: #E5F1F5;}
.gris_bleu               {color: #BFCFDB;}
.gris_bleu_fonce         {color: #8BA0AF;}
.gris_bleu_fonce2        {color: #626F85;}
.jaune                   {color: #FDCB27;}
.rouge                   {color: #FF4E4E;}
.vert                    {color: #57B573;}
.colonnes                {width:   360px;}

/* ###################
         GENERAL
   ###################*/

:root {
    --bleu_fonce: #18425F;
    --bleu_electrique: #0D85D2;
    --bleu_electrique_clair: #0F9BF2;
    --bleu_electrique_clair2: #08B0EF;
    --bleu_blanc: #D9E9F5;
    --bleu_blanc2: #E5F1F5;
    --gris_bleu: #BFCFDB;
    --gris_bleu_fonce: #8BA0AF;
    --gris_bleu_fonce2: #626F85;
    --jaune: #FDCB27;
    --rouge: #FF4E4E;
    --vert: #57B573;
    --colonnes: 360px;
}

* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
}

html {
    scroll-behavior: smooth;
}

body, select {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #4E4E4E;
}

::placeholder {
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .not_mobile {
        display: none !important;
    }

    .full_width_on_mobile {
        width: 100% !important;
    }
}

@media screen and (max-width: 1262px) {
    .not_tablet {
        display: none !important;
    }
}

@media screen and (min-width: 769px) {
    .only_mobile {
        display: none !important;
    }
}

header, section, footer, aside, nav, main, article, figure, details {
    display: block;
}

main {
    margin: 20px auto 0;
    clear: both;
}

header.slim + main {
    margin: 70px auto 0;
}

ul,
ol {
    margin: 1em 0;
}

ul li,
ol li {
    margin-left: 30px;
}

ul.clean,
ol.clean {
    padding-left: 0;
    list-style: none;
}

ol ul {
    list-style-type: disc;
}

a {
    color: #0D85D2;
    text-decoration: none;
}

a.text {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.text:hover {
    text-decoration: none;
}

:focus,
:-moz-focusring {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

div.readmore {
    text-align: right;
}

article p,
p.justify,
ul.justify,
ol.justify {
    text-align: justify;
    font-size: 15px;
}

label.justify {
    text-align: justify;
    font-size: 13px;
}

section.text p {
    font-size: 13px;
}

p.justify + p.justify,
ul.justify + ul.justify,
p.justify + ul.justify,
ul.justify + p.justify {
    margin-top: 1em;
}

p + p,
h1 + p,
h2 + p:not(.h4),
h3 + p,
h4 + p,
h5 + p,
p + p,
p + h1,
p + h2,
p + h3,
p + h4,
p + h5 {
    margin-top: 1em;
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 1em;
}

blockquote {
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    padding: 2px 8px 2px 20px;
    border-style: solid;
    border-color: #CCC;
    border-width: 0 0 0 5px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

math, math[display="inline"] {
    display: inline;
    font-family: "Cambria Math",CMSY10, CMEX10, Symbol, Times;
    font-style: normal;
    font-size: 17px;
}

math[display="block"] {
    display: block;
    text-align: center;
    font-family: "Cambria Math",CMSY10, CMEX10, Symbol, Times;
    font-style: normal;
    font-size: 17px;
}

article.summary section.image {
    vertical-align: top;
    background-size: cover;
    background-position: center center;
    position: relative;
}

article.summary section.image > img {
    width: 100%;
    /*height: 100%;*/
    object-fit: cover;
}

article.summary section.image section.tag {
    background-color: #FDCB27;
    color: var(--bleu_fonce);
    padding: 7px 7px 7px 14px;
    font-size: 13px;
    top: 20px;
    position: absolute !important;
    text-transform: uppercase;
}

article.summary section.image section.tag:empty {
    display: none;
}

article.summary section.image section.bandeau {
    background-color: rgba(0, 0, 0, 0.6);
    color: #FDCB27;
    padding: 9px 12px;
    font-weight: bold;
    width: 100%;
    bottom: 0;
    position: absolute !important;
    text-transform: uppercase;
}

article.summary section.image section.bandeau.right,
article.summary section.text section.bandeau.right {
    background-color: transparent !important;
    color: #FFFFFF;
    text-align: right;
    text-transform: none;
}

article.summary section.image section.angle {
    background-image: url("/img/angle_bleu.svg");
    color: #FFFFFF;
    padding-left: 5px;
    font-size: 32px;
    font-weight: bold;
    line-height: 25px;
    vertical-align: top;
    width: 46px;
    height: 46px;
    top: 0;
    position: absolute !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

article.summary section.image section.angle.active {
    color: #FDCB27;
}

article.summary section.image section.highlight {
    background-color: #0D85D2;
    color: #FFFFFF;
    padding: 7px 10px 7px 10px;
    font-size: 13px;
    font-weight: bold;
    top: 20px;
    right: 0;
    position: absolute !important;
    text-transform: uppercase;
}

article.summary section.image section.exclusive {
    background-color: #FDCB27;
    color: #18425F;
    padding: 7px 10px 7px 10px;
    font-size: 13px;
    font-weight: bold;
    bottom: 33px;
    right: 0;
    position: absolute !important;
    text-transform: uppercase;
}

article.summary section.image section.mention {
    background-color: #F5F5F5;
    color: #4E4E4E;
    padding: 0 6px 0 6px;
    font-size: 11px;
    bottom: 35px;
    left: 0;
    position: absolute !important;
}

article.summary section.image section.photo360,
#photo360_tag,
article.summary section.image section.video,
#video_tag {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    color: #FFFFFF;
    text-align: center;
    padding-top: 35px;
    font-weight: bold;
    font-size: 15px;
}

article.summary section.image section.photo360,
#photo360_tag {
    background-image: url("/img/icon_photo360.svg");
}

article.summary section.image section.video,
#video_tag {
    background-image: url("/img/icon_youtube.svg");
    background-repeat: no-repeat;
    height: 30px;
    padding-top: 0px;
}

article.summary section.image section.photo360 {
    bottom: 50px;
}

article.summary section.image section.video {
    bottom: 40px;
}

article.summary section.image section.photo360 + section.video {
    bottom: 90px;
}

article.summary section.text {
    position: relative;
}

article.summary.image_on_left section.image {
    float: left;
}

article.summary.image_on_left section.text {
    float: right;
    padding: 20px;
}

article.summary.image_on_left section.text h4 {
    font-size: 20px;
    font-weight: bold;
}
article.summary.image_on_left section.text h5 {
    font-size: 16px;
    font-weight: normal;
}

article.summary.image_on_left section.text section.bandeau {
    background-color: var(--bleu_fonce);
    color: #FFFFFF;
    padding: 9px 12px;
    left: 0; right: 0;
    bottom: 0;
    position: absolute !important;
}

article.summary.image_on_left footer {
    padding-top: 5px;
    padding-left: 360px;
    clear: both;
}

article.summary.image_on_top section.image {
}

nav.square {
    text-align: center;
    margin-bottom: 20px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

nav.square div {
    width: 10px; height: 10px;
    background: #8BA0AF;
    display: inline-block;
}

nav.square div.active {
    background: #FDCB27;
}

nav.square div + div {
    margin-left: 8px;
}

.electric_bg {
    color: #FFFFFF;
    background-color: #0D85D2;
}

.white_bg {
    color: var(--bleu_fonce);
    background-color: #FFFFFF;
}

.yellow_bg {
    color: var(--bleu_fonce);
    background-color: #FDCB27;
}

.normal {
    font-style: normal !important;
    font-weight: normal !important;
}

.italic {
    font-style: italic !important;
}

.bold {
    font-weight: bold !important;
}

.underline {
    text-decoration: underline;
}

.strike {
    text-decoration: line-through;
}

.clearfix::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.flex {
    display: flex;
    flex-flow: row wrap;
}

.flex > header,
.flex > footer {
    flex-basis: 100%;
    max-width: 100%;
}

.clear {
    clear: both;
}

.invisible {
    visibility: hidden;
}

.separator {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

button,
.button,
[onclick],
input[type="submit"],
input[type="button"] {
    cursor: pointer;
}

input[type="submit"] {
    -webkit-appearance: none;
}

button:focus,
.button:focus,
[onclick]:focus,
input[type="submit"]:focus,
input[type="button"]:focus {
    /*outline: 1px #FFFFFF dotted;*/
    outline: unset;
}

button {
    border: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

header.pagination,
footer.pagination {
    width: 100%;
    background-color: #D9E9F5;
    padding: 10px;
    line-height: 40px;
    text-align: center;
    position: relative;
    margin: 20px auto 20px auto;
    clear: both;
}

header.pagination span,
footer.pagination span {
    margin: 0 0 0 auto;
}

header.pagination span strong,
header.pagination span a:not(.next):not(.prev),
footer.pagination span strong,
footer.pagination span a:not(.next):not(.prev) {
    padding: 0 0.5em;
}

header.pagination span a.prev,
footer.pagination span a.prev,
header.pagination span a.next,
footer.pagination span a.next {
    font-size: 20px;
    padding: 0 10px;
}

/*header.pagination span a.prev::after,
footer.pagination span a.prev::after {
    content: 'pr�c�dente';
    padding-left: 0.5em;
}

header.pagination span a.next::before,
footer.pagination span a.next::before {
    content: 'suivante';
    padding-right: 0.5em;
}*/

header.pagination nav.sort,
footer.pagination nav.sort,
header.pagination nav.clear,
footer.pagination nav.clear {
    position: absolute;
}

header.pagination nav.clear,
footer.pagination nav.clear {
    top: 10px;
    right: 10px;
}

@media screen and (max-width: 945px) {
    header.pagination nav.sort,
    footer.pagination nav.sort,
    header.pagination nav.clear,
    footer.pagination nav.clear {
        position: relative;
    }

    header.pagination nav.sort,
    footer.pagination nav.sort,
    header.pagination nav.clear,
    footer.pagination nav.clear {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1370px) {
    main.recherche_biens:not(.large) header.pagination nav.sort,
    main.recherche_biens:not(.large) footer.pagination nav.sort,
    main.recherche_biens:not(.large) header.pagination nav.clear,
    main.recherche_biens:not(.large) footer.pagination nav.clear {
        position: relative;
    }

    main.recherche_biens:not(.large) header.pagination nav.sort,
    main.recherche_biens:not(.large) footer.pagination nav.sort,
    main.recherche_biens:not(.large) header.pagination nav.clear,
    main.recherche_biens:not(.large) footer.pagination nav.clear {
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 1371px) {
    main.recherche_biens.small header.pagination nav.sort,
    main.recherche_biens.small footer.pagination nav.sort,
    main.recherche_biens.small header.pagination nav.clear,
    main.recherche_biens.small footer.pagination nav.clear {
        position: relative;
    }

    main.recherche_biens.small header.pagination nav.sort,
    main.recherche_biens.small footer.pagination nav.sort,
    main.recherche_biens.small header.pagination nav.clear,
    main.recherche_biens.small footer.pagination nav.clear {
        margin-bottom: 10px;
    }
}

@media print {
    nav {
        display: none !important;
    }

    .donotprint {
        display: none !important;
    }
}

@media screen {
    .printonly {
        display: none !important;
    }
}

/* ###################
       FORMULAIRES
   ###################*/

form input,
form textarea,
#protip .content {
    border: 1px #BFCFDB solid;
    border-radius: 3px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #4E4E4E;
}

form input:focus,
form textarea:focus {
    border-color: var(--bleu_electrique);
}

form textarea {
    height: calc(6em + 10px);
    resize: vertical;
}

form input.bgimg,
form textarea.bgimg {
    background-image: url('/img/icon_pen.svg'), linear-gradient(to right, #E5F1F5 32px, #FFFFFF 32px);
    background-position: 8px 8px, 0% 0%;
    background-repeat: no-repeat, no-repeat;
    min-height: 32px;
    padding: 2px 2px 2px 36px;
}

form textarea.bgimg {
    padding: 4px 4px 4px 36px;
}

form input[type="email"].bgimg {
    background-image: url('/img/icon_arobase.svg'), linear-gradient(to right, #E5F1F5 32px, #FFFFFF 32px);
}

form input[type="tel"].bgimg {
    background-image: url('/img/icon_phone2.svg'), linear-gradient(to right, #E5F1F5 32px, #FFFFFF 32px);
}

form textarea.bgimg {
    background-image: url('/img/icon_message.svg'), linear-gradient(to right, #E5F1F5 32px, #FFFFFF 32px);
}

label,
form input[type="submit"],
form input[type="button"],
a.button,
a.prev,
a.next {
    height: 40px;
    background-color: #0D85D2;
    color: #FFFFFF;
    display: inline-block;
    font-weight: bold;
    line-height: 40px;
    padding: 0 22px;
    vertical-align: top;
    text-align: left;
    white-space: nowrap;
}

form input[type="submit"]:hover,
form input[type="button"]:hover,
a.button:hover,
a.prev:hover,
a.next:hover {
    text-decoration: none !important;
    color: var(--bleu_fonce) !important;
    background-color: #FDCB27 !important;
}

form input[type="submit"],
form input[type="button"] {
    width: auto;
    display: block;
    margin: 1em 0 0 auto;
    border: none;
    border-radius: 0;
}

form input[type="number"] {
    -moz-appearance: textfield;
}

form input::-webkit-outer-spin-button,
form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

form span.obligatoire {
    color: #FF0000;
}

form.contact input[type="checkbox"],
form.contact input[type="radio"] {
    position: static;
    left: auto;
}

form.contact label {
    display: block;
    height: auto;
    background-color: transparent;
    color: inherit;
    line-height: normal;
    padding: 0;
    vertical-align: inherit;
    font-weight: normal;
}

form.contact label + label {
    margin-top: 0.5em;
}

/* ###################
         PROTIP
   ###################*/

#protip {
    position: fixed;
    z-index: 100;
    left: 60px;
    top: 15px;
}

#protip .content {
    min-width: 200px;
    max-width: 240px;
    min-height: 100px;
    background-color: #FFFFFF;
    padding: 5px 11px 5px 5px;
    font-size: 15px;
}

#protip .arrow {
    border-top: 1px #BFCFDB solid;
    border-left: 1px #BFCFDB solid;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    transform: rotate(-45deg);
    position: absolute;
    top: 5px;
    left: -5px;
}
#protip .close {
    position: absolute;
    top: 0;
    right: 6px;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

/* ###################
         ENT�TE
   ###################*/

body > header nav ul {
    margin: 0;
    padding-left: 0;
    display: inline;
}

body > header nav li {
    display: inline-block;
    margin-left: 0;
}

/**********************/

#header_blue {
    position: fixed;
    background-color: var(--bleu_fonce);
    height: 55px;
    width: 100%;
    top: 0;
    z-index: 10;
}

#header_blue nav.blue {
    line-height: 55px;
    color: #BFCFDB;
    max-width: 1140px;
    margin: 0 auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    position: relative;
}

#header_blue nav.blue ul:first-child {
    font-size: 14px;
}

#header_blue nav.blue li {
    margin-right: 10px;
}

#header_blue nav.blue img {
    vertical-align: -2px;
    margin-right: 5px;
}

#header_blue nav.blue a {
    color: #BFCFDB;
    text-decoration: none;
}

#header_blue nav.blue div a {
    margin-left: 1em;
}

#header_blue nav.blue a:not(.text):not(.button):hover {
    color: #0D85D2;
    text-decoration: underline;
}

#header_blue a.irels {
    font-size: 1.1em;
    background-color: transparent;
    color: #FFFFFF !important;
    display: inline-block;
    position: relative;
    height: 35px;
    vertical-align: middle;
    width: 230px;
}

#header_blue a.irels img {
    position: absolute;
    top: 2.5px;
    height: 30px;
}

#header_blue a.irels span {
    display: block;
    position: absolute;
    top: 0;
    height: 17.5px;
    line-height: 17.5px;
    left: 130px;
}

#header_blue a.irels span + span {
    top: 17.5px;
}

#header_blue div {
    position: absolute;
    right: 0;
    top: 0;
    height: 55px;
}

#header_blue div a.button {
    color: #FFFFFF;
    background-repeat: no-repeat;
    background-color: #0D85D2;
    height: 35px;
    line-height: 35px;
    font-weight: bold;
    font-size: 13px;
    margin-left: 1em;
    padding: 0 14px 0 45px;
    vertical-align: baseline;
    display: inline-block;
}

#header_blue div a.button:hover {
    text-decoration: none;
    color: var(--bleu_fonce);
    background-color: #FDCB27;
}

#header_blue div a.button.contact {
    background-image: url('/img/button_contact_bg.svg');
    background-size: 24px auto;
    background-position: 10px 9px;
}

#header_blue div a.button.contact:hover {
    background-position: 10px -20px;
}

#header_blue div a.button.search {
    background-image: url('/img/button_search_bg.svg');
    background-size: 22px auto;
    background-position: 10px -35px;
}

#header_blue div a.button.search:hover {
    background-position: 10px -2px;
}

body > header.slim #header_blue {
    display: none;
}

#lang_selector {
    margin-left: 1em;
    font-size: 14px;
    font-family: Roboto;
}

#header_white #lang_selector li {
    margin-right: 5px;
}

#header_blue #lang_selector li a {
    margin-left: 0;
}

#lang_selector .active {
    font-weight: bold;
}

@media screen and (max-width: 945px) {
    #header_blue {
        display: none;
    }
}

/**********************/

#header_white {
    position: fixed;
    background-color: #FFFFFF;
    width: 100%;
    top: 55px;
    z-index: 10;
}

#header_white.shadow {
    box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.5);
}

#header_white nav.white {
    height: 90px;
    max-width: 1140px;
    margin: 0 auto;
    font-family: Raleway, "Trebuchet MS", sans-serif;
    font-size: 11px;
    position: relative;
}

#header_white.slim nav.white,
#header_white.small nav.white {
    height: 50px;
}

#header_white nav.white a.logo img {
    vertical-align: top;
    margin-top: 10px;
    height: 70px;
}

#header_white.slim nav.white a.logo img,
#header_white.small nav.white a.logo img {
    margin-top: 5px;
    height: 40px;
}

body > header.slim #header_white {
    position: static;
    top: 0;
}

#burger_menu {
    display: none;
    height: 50px;
    position: absolute;
    padding: 5px;
    top: 0;
    -moz-animation-duration: 300ms;
    -moz-animation-timing-function: ease-out;
    -webkit-animation-duration: 300ms;
    -webkit-animation-timing-function: ease-out;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
}

#main_menu {
    position: absolute;
    right: 0;
}

#main_menu > li.compact:first-child {
    background-color: #FF4E4E;
}

#main_menu > li.compact:first-child a:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
}

#header_white nav.white > ul > li {
    line-height: 82px;
    height: 90px;
    margin: 0 10px;
    text-transform: uppercase;
    /*position: relative;*/
    border-top: 5px solid #FFFFFF;
    border-bottom: 5px solid transparent;
    vertical-align: top;
}

#header_white.slim nav.white > ul > li,
#header_white.small nav.white > ul > li {
    line-height: 42px;
    height: 50px;
}

#header_white nav.white > ul > li:last-child {
    margin-right: 0;
}

@media screen and (min-width: 946px) {
    #header_white nav.white > ul > li:last-child ul.menu {
        right: 0;
    }
}

#header_white nav.white > ul > li.active {
    border-bottom: 5px solid #0D85D2;
}

#header_white nav.white > ul > li:hover {
    border-top: 5px solid #8BA0AF;
}

#header_white nav.white > ul > li a {
    color: var(--bleu_fonce);
}

#header_white nav.white > ul > li span {
    font-weight: bold;
    color: #0D85D2;
    font-size: 13px;
    margin-left: 3px;
    vertical-align: top;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#header_white nav.white > ul > li.active > a,
#header_white nav.white > ul > li a:hover {
    color: #0D85D2;
}

#header_white nav.white > ul > li a:hover span {
    text-decoration: none;
}

#header_white nav.white > ul li ul {
    position: absolute;
    background: #D9E9F5;
    top: 65px;
}

#header_white.slim nav.white > ul li ul,
#header_white.small nav.white > ul li ul {
    top: 45px;
}

#header_white nav.white > ul li ul.toggled {
    display: none;
}

#header_white nav.white > ul li ul li {
    display: block;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    padding: 0 10px;
    margin: 0;
}

#header_white nav.white > ul li ul li:hover {
    color: #FFFFFF;
    background-color: #0D85D2;
}

#header_white nav.white .compact {
    display: none;
}

#header_white nav.white div.compact {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--bleu_fonce);
}

#header_white nav.white div.compact.contact {
    position: fixed;
    width: 215px;
    z-index: 5;
}


#header_white nav.white div.compact.contact,
#header_white nav.white .hidden + div.compact.contact {
    left: -215px;
    -moz-transition: left 150ms ease-out;
    -webkit-transition: left 150ms ease-out;
    transition: left 150ms ease-out;
    /*-moz-animation-duration: 150ms;
    -moz-animation-timing-function: ease-out;
    -moz-animation-name: -moz-slideout;
    -webkit-animation-duration: 150ms;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-name: -webkit-slideout;
    animation-duration: 150ms;
    animation-timing-function: ease-out;
    animation-name: slideout;*/
}

#header_white nav.white .displayed + div.compact.contact {
    left: 0;
    -moz-transition: left 150ms ease-out;
    -webkit-transition: left 150ms ease-out;
    transition: left 150ms ease-out;
    /*-moz-animation-duration: 150ms;
    -moz-animation-timing-function: ease-out;
    -moz-animation-name: -moz-slidein;
    -webkit-animation-duration: 150ms;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-name: -webkit-slidein;
    animation-duration: 150ms;
    animation-timing-function: ease-out;
    animation-name: slidein;*/
}

#header_white nav.white div.compact.notification {
    top: 4px;
    right: 4px;
    left: unset;
    border: 1px solid #FFFFFF;
    width: 26px;
    height: 26px;
    border-radius: 13px;
}

#header_white nav.white div.compact li {
    text-transform: uppercase;
    line-height: 24px;
    height: auto;
    min-height: 25px;
    position: relative;
}

#header_white nav.white div.compact a {
    color: #BFCFDB;
    display: block;
}

#header_white nav.white div.compact ul:not(#lang_selector) > li > a {
    padding-left: 10px;
    font-weight: normal;
}

#header_white nav.white div.compact a.telephone img {
    vertical-align: -4px;
    margin-left: 1px;
    margin-right: 4px;
}

#header_white nav.white div.compact a.form img {
    vertical-align: -2px;
    margin-right: 5px;
}

#menu_shade {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
}

@media screen and (max-width: 945px) {
    #header_white nav.white {
        height: 50px;
    }

    #main_menu {
        display: block;
        position: fixed;
        background: #0D85D2;
        color: #FFFFFF;
        left: -215px;
        right: auto;
        bottom: 50px;
        top: 0;
        z-index: 5;
        width: 215px;
        border-right: 1px solid var(--bleu_fonce);
        overflow: auto;
        -moz-transition: left 150ms ease-out;
        -webkit-transition: left 150ms ease-out;
        transition: left 150ms ease-out;
    }

    #main_menu.hidden {
        left: -215px;
        /*-moz-animation-duration: 150ms;
        -moz-animation-timing-function: ease-out;
        -moz-animation-name: -moz-slideout;
        -webkit-animation-duration: 150ms;
        -webkit-animation-timing-function: ease-out;
        -webkit-animation-name: -webkit-slideout;
        animation-duration: 150ms;
        animation-timing-function: ease-out;
        animation-name: slideout;*/
    }

    #main_menu.displayed {
        left: 0;
        /*-moz-animation-duration: 150ms;
        -moz-animation-timing-function: ease-out;
        -moz-animation-name: -moz-slidein;
        -webkit-animation-duration: 150ms;
        -webkit-animation-timing-function: ease-out;
        -webkit-animation-name: -webkit-slidein;
        animation-duration: 150ms;
        animation-timing-function: ease-out;
        animation-name: slidein;*/
    }

    #main_menu.displayed ~ #menu_shade {
        display: block;
    }

    #main_menu li {
        display: block;
    }

    #header_white {
        top: 0;
    }

    #header_white nav.white a.logo {
        margin-right: 0;
    }

    #header_white nav.white a.logo img {
        display: block;
        margin: 0 auto;
        padding-top: 2px;
        height: 46px;
        width: auto;
    }

    #burger_menu {
        display: inline;
    }

    #header_white nav.white > ul > li {
        line-height: 34px;
        min-height: 35px;
        height: auto;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        position: relative;
        border-top: none;
        border-bottom: 1px solid var(--bleu_fonce);
        vertical-align: top;
    }

    #header_white nav.white > ul > li.active {
        border-top: none;
        border-bottom: 1px solid var(--bleu_fonce);
    }

    #header_white nav.white > ul > li.active > a {
        color: #FFFFFF;
    }

    #header_white nav.white > ul > li span {
        color: #FFFFFF;
        position: absolute;
        right: 10px;
        top: 0;
    }

    #header_white nav.white > ul > li > a {
        color: #FFFFFF;
        display: block;
        padding: 0 30px 0 10px;
        outline: none !important;
    }

    #header_white nav.white > ul > li > a:hover {
        background-color: #FDCB27;
        color: var(--bleu_fonce);
        text-decoration: none;
    }

    #header_white nav.white > ul > li > a:hover + span {
        color: var(--bleu_fonce);
    }

    #header_white nav.white > ul li ul {
        position: relative;
        top: 0px;
        background: #FFFFFF;
        color: #0D85D2;
        display: block;
    }

    #header_white nav.white > ul > li:hover {
        border-top: none;
    }

    #header_white nav.white > ul li ul li:hover {
        color: #FFFFFF;
        background-color: #0D85D2;
    }

    #header_white nav.white .compact {
        display: block;
    }

    #header_white nav.white > ul li ul a {
        color: #0D85D2;
    }
}

@media screen and (min-width: 946px) {
    #header_white nav.white > ul li:hover ul {
        display: block;
    }

    #header_white nav.white > ul > li > a {
        /*display: inline-block;
        line-height: 40px;*/
    }
}

/*@media screen and (min-width: 946px) and (max-width: 1040px) {
    #main_menu li:first-child {
        display: none;
    }
}*/

/**********************/

#slider .bg,
#slider {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#slider .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 1500ms;
}

#slider.fullscreen {
    width: 100% !important;
    height: 100% !important;
}

#slider.fullscreen:not(.photo360) {
    background-size: contain;
}

#slider.header {
    background-image: url("/img/slider_bg_1.jpg");
    width: 100%;
    margin-top: 145px;
    overflow: visible;
}

#slider.header.home,
#slider.header.franchise_expo_paris {
    height: 480px;
    box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.5);
}

#slider.header.home .bg {
    background-image: url("/img/home_slider_bg_line.svg"), url("/img/home_slider_bg_poly.svg"), url("/img/slider_bg_1.jpg");
    background-position: calc(50% + 150px) top, calc(50% + 223px) -166px, center center;
    background-size: auto, auto, cover;
}

#slider.header.construction {
    background-image: none;
    height: 500px;
    overflow: visible;
    text-align: center;
}

#slider.header.entreprise {
    background-image: url("/img/bg_entreprise.jpg");
    height: 406px;
}

#slider.header.entrepot {
    background-image: url("/img/bg_industrie.png");
    height: 406px;
}

#slider.header.activites {
    background-image: url("/img/bg_activites.jpg");
    height: 406px;
}

#slider.header.commerce {
    background-image: url("/img/bg_commerce.jpg");
    height: 406px;
}

#slider.header.publications {
    background-image: url("/img/bg_publications.jpg");
    height: 344px;
}

#slider.header.blog {
    background-image: url("/img/bg_blog.jpg");
    height: 344px;
}

#slider.header.inscription_dispolog {
    background-image: url("/img/bg_inscription_dispolog.jpg");
    height: 406px;
}

#slider.header.inscription_mapic {
    background-image: url("/img/bg_commerce.jpg");
    height: 406px;
}

#slider.header.franchise_expo_paris {
    background-image: url("/img/bg_commerce3.jpg");
    height: 406px;
}

#slider.header.franchise_expo_paris .content h1 {
    text-transform: none;
    font-weight: normal;
    position: static;
    margin: auto;
    width: 100%;
    font-size: 27px;
    text-align: center;
    color: #FFFFFF;
    /*text-shadow: 0 0 2px #000000, 1px 1px 0 #000000, -1px -1px 0 #000000, -1px 1px 0 #000000, 1px -1px 0 #000000;*/
}

#slider.header.decarbonation {
    background-image: url("/img/bg_decarbonation.jpg");
    height: 405px;
}

#slider.header.decarbonation #slider_h1 {
    font-size: 42px;
    font-weight: bold;
    background: none;
    width: 100%;
    text-align: center;
    margin-top: 70px;
    padding-left: 10px;
    padding-right: 10px;
}

#slider.header.decarbonation p {
    font-size: 26px;
    font-weight: bold;
    background: none;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    padding-top: 220px;
    padding-left: 10px;
    padding-right: 10px;
}

#slider.header.decarbonation div {
    margin-top: 2em;
    text-align: center;
}

#slider.header.decarbonation div a + a {
    margin-left: 2em;
}

#slider.header.decarbonation span {
    color: var(--bleu_fonce);
    font-weight: bolder;
}

#slider.header section.content {
    width: 100%;
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: visible;
}

#slider.header.construction section.content {
    position: absolute;
    text-align: left;
    top: 0;
    left: 0;
    right: 0;
}

#slider nav.button,
#shade nav.button {
    color: #FFFFFF;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.6);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 10px 10px;
    position: absolute;
    top: calc(50% - 25px);
    font-size: 60px;
    font-weight: lighter;
    line-height: 40px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    z-index: 1;
}

#slider nav.button.gauche,
#shade nav.button.gauche {
    left: 0;
    background-image: url("/img/fleche_gauche.svg");
    /*padding-left: 10px;*/
}

#slider nav.button.droite,
#shade nav.button.droite {
    right: 0;
    background-image: url("/img/fleche_droite.svg");
    /*padding-right: 10px;*/
}

@media screen and (min-width: 768px) {
    #slider nav.button:hover,
    #shade nav.button:hover {
        background-color: #0D85D2;
    }
}

#slider nav.button img {
    width: 30px;
}

#slider section.content h1 {
    /*color: #FFFFFF;
    font-size: 42px;
    font-weight: bold;
    padding: 100px 0 5px;
    text-shadow: -1px 0 0 #000000, 0 1px 0 #000000, 1px 0 0 #000000, 0 -1px 0 #000000,
                 -1px -1px 0 #000000, 1px 1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
    text-align: center;
    width: auto;
    top: 79px;
    font-size: 25px;
    font-weight: bold;
    color: var(--bleu_fonce);
    background: #FFFFFF;
    padding: 5px 10px;
    position: absolute;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);*/
    color: white;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    text-transform: uppercase;
    text-align: left;
    width: 450px;
    padding-left: 5px;
}

#slider.home section.content h1 {
    font-size: 35px;
}

/*#slider section.content h1 span {
    color: #0D85D2;
    font-family: Candal, sans-serif;
    font-size: 100px;
    font-weight: bolder;
    vertical-align: -30px;
}*/

#slider section.content h2 {
    /*color: #FFFFFF;
    font-size: 23px;
    font-weight: normal;
    padding: 5px 0 30px;
    text-shadow: -1px 0 0 #000000, 0 1px 0 #000000, 1px 0 0 #000000, 0 -1px 0 #000000,
                 -1px -1px 0 #000000, 1px 1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
    text-align: center;*/
    width: auto;
    top: 140px;
    font-size: 20px;
    font-weight: normal;
    color: #FFFFFF;
    background: #0D85D2;
    padding: 5px 10px;
    position: absolute;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
}

#slider section.content p {
    padding-top: 160px;
    padding-left: 5px;
    margin-top: 0;
    color: white;
    width: 400px;
    text-align: justify;
}

#slider section.content p + p {
    margin-top: 1em;
    padding-top: 0;
}

#slider section.side_content {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
}

#slider section.side_content {
    bottom: 146px;
}

#slider.publications section.side_content,
#slider.blog section.side_content {
    bottom: 84px;
}

#slider.home section.side_content {
    bottom: 146px;
}

#slider.construction section.side_content {
    bottom: 149px;
    background-color: rgba(255, 255, 255, 0.6);
}

#slider section.content article {
    width: 300px;
    min-height: 75px;
    background-repeat: no-repeat;
    background-position: 10px 25px;
    right: 0;
    margin: 0 auto;
    padding: 10px 10px 5px 56px;
    overflow: auto;
    color: #4E4E4E;
    font-size: 12px;
}

#slider.construction section.content article {
    /*background-color: rgba(255, 255, 255, 0.6);*/
}

#slider section.content article div + div {
    margin-top: 0.5em;
}

#slider section.content article div + div:before {
    content: "";
    display: block;
    height: 1px;
    width: 80%;
    margin: 0 auto 0.25em;
    background: #0D85D2;
}

#slider section.content #header_latest_event {
    background-image: url("/img/icon_calendar.svg");
    top: 95px;
    right: 0;
    position: absolute;
}

#slider section.content #header_latest_news {
    background-image: url("/img/icon_actu.svg");
    top: 0;
    right: 0;
}

#slider section.content a:hover #header_latest_news div {
    text-decoration: underline;
}

#slider section.content #header_latest_recrutement {
    background-image: url("/img/icon_recrutement.svg");
    top: 190px;
    right: 0;
    height: 70px;
}

#slider section.content #header_latest_news a,
#slider section.content #header_latest_event a,
#slider section.content #header_latest_recrutement a {
    color: inherit;
}

#slider section.content article h3 {
    margin-bottom: 0.1em;
    color: #0D85D2;
    text-transform: uppercase;
}

#slider_selector {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
}

#slider_selector div {
    width: 20px;
    height: 20px;
    background-color: rgba(139, 160, 175, 0.6);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    display: inline-block;
}

#slider_selector div + div {
    margin-left: 20px;
}

@media screen and (min-width: 1891px) {
    #slider section.side_content {
        bottom: 84px;
    }

    #slider.construction section.side_content {
        bottom: 87px;
    }
}

/**********************/

@media screen and (max-width: 480px) {
    #slider.header {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    #slider.header {
        display: none;
    }

    #slider.header.home,
    #slider.header.entreprise,
    #slider.header.entrepot,
    #slider.header.activites,
    #slider.header.commerce {
        display: block;
        height: 160px;
    }

    #slider.header.franchise_expo_paris {
        display: block;
        height: 360px;
    }

    #slider.header.franchise_expo_paris .content {
        height: 200px;
        background-color: rgba(24,66,95,.5);
    }

    #slider.header.franchise_expo_paris .content h1 {
        /*margin: 0 !important;*/
        text-shadow: none !important;
        font-size: 22px;
    }

    #slider.header.publications,
    #slider.header.blog {
        display: block;
        height: 110px;
    }

    #slider.header .content {
        display: none;
    }
}

@media screen and (max-width: 945px) {
    #slider.header {
        margin-top: 50px;
    }
}

@media screen and (max-width: 1100px) {
    #slider section.content p {
        width: 380px;
        padding-top: 170px;
    }

    /*#slider section.content #header_latest_event {
        top: 20px;
        right: 0;
    }

    #slider section.content #header_latest_news {
        top: 180px;
        right: 0;
    }*/
}

@media print {
    body > header {
        display: none;
    }
}

/* ###################
     BARRE FLOTTANTE
   ###################*/

#stickybar {
    position: absolute;
    top: 877px;
    left: -10px;
    margin-top: 70px;
    z-index: 2;
}

body > header.slim #stickybar {
    display: none;
}

#stickybar a {
    display: block;
    width: 61px;
}

#stickybar section {
    width: 61px; height: 51px;
    padding: 35px 0 0 10px;
    color: #FFFFFF;
    font-size: 12px;
    background-color: #0D85D2;
    background-position: 10px 0;
    background-repeat: no-repeat;
    background-size: 50px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    text-align: center;
    position: relative;
}

#stickyshare {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    margin-left: -305px;
    margin-top: 15px;
    transition: margin-left 300ms;
}

#stickyshare a {
    display: inline;
    width: auto;
}

#stickyshare a section {
    margin-top: 0;
}

#stickybar > a:not(:first-child) > section {
    margin-top: 15px;
}

#stickyshare section {
    margin-top: 0;
    display: inline-block;
    box-shadow: none;
}

div.notification {
    width: 24px; height: 24px;
    border-radius: 12px;
    color: #FFFFFF;
    background-color: #FF4E4E !important;
    position: absolute;
    top: -8px;
    right: -8px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
}

#protip div.notification {
    position: static;
    display: inline-block;
}

#stickybar section.google,
#stickybar section.linkedin,
#stickybar section.twitter,
#stickybar section.facebook,
#stickybar section.email {
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: 18px 1px;
}

#stickybar section.blog {
    background-image: url("/img/icon_blog.svg");
}

#stickybar section.google {
    background-image: url("/img/icon_google+.svg");
}

#stickybar section.linkedin {
    background-image: url("/img/icon_linkedin.svg");
}

#stickybar section.twitter {
    background-image: url("/img/icon_twitter.svg");
}

#stickybar section.facebook {
    background-image: url("/img/icon_facebook.svg");
}

#stickybar section.email {
    background-image: url("/img/icon_email_blanc.svg");
    background-position: 18px 6px;
}

#stickybar section.partager {
    background-image: url("/img/icon_partager.svg");
}

#stickybar section.selection {
    background-image: url("/img/icon_selection.svg");
}

#stickybar section.lexique {
    background-image: url("/img/icon_lexique.svg");
}

#stickybar section.selection div.notification {
    -moz-animation-duration: 300ms;
    -moz-animation-timing-function: ease-out;
    -webkit-animation-duration: 300ms;
    -webkit-animation-timing-function: ease-out;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
}

@media print {
    #stickybar {
        display: none;
    }
}

@media screen and (max-width: 1262px) {
    #stickybar section.blog,
    #stickybar #stickyshare,
    #stickybar section.lexique {
        display: none;
    }
}

@media screen and (max-width: 945px) {
    #stickybar section.selection {
        display: none;
    }
}

/* ###################
   MOTEUR DE RECHERCHE
   ###################*/

#search {
    position: relative;
    background-color: var(--bleu_fonce);
    width: 100%;
    margin-top: 145px;
    /*box-shadow: -2px 10px 10px rgba(0, 0, 0, 0.5);*/
}

#slider #search {
    position: absolute;
    bottom: 0;
    margin-top: 0;
    background-color: rgba(24, 66, 95, 0.5);
}

#slider.header.home #search {
    background-color: rgba(24, 66, 95, 0.75);
}

#slider #search.advanced {
    bottom: -62px;
    z-index: 2;
}

#slider.construction #search {
    bottom: 3px;
}

#slider + section.search,
#slider + script + section.search {
    margin-top: 0;
}

#search.full {
    min-height: 84px;
}

#search.fixed {
    position: fixed;
    top: 145px;
    z-index: 3;
}

#search.slim {
    min-height: 84px;
    margin-top: 145px;
}

#search.slim.fixed {
    margin-top: 0;
}

#search nav.search {
    margin: 0 auto;
    position: relative;
    overflow: visible;
    padding-top: 22px;
    /*padding-bottom: 0.1px;*/
    text-align: center;
}

#search.full nav.search {
    max-width: 1140px;
    min-height: 84px;
}

#search.slim nav.search {
    max-width: 1920px;
    min-height: 84px;
}

#search nav.search #biens_search section {
    max-width: 945px;
}

#search nav.search #articles_search section {
    max-width: 1140px;
}

#search nav.search section {
    height: 40px;
    margin: 0 auto 22px auto;
    text-align: center;
    vertical-align: top;
    display: inline-block;
}

#search.slim nav.search section {
    text-align: right;
    margin-right: 5px;
}

#search.slim nav.search section + section {
    margin-left: 5px;
    margin-right: 0;
    text-align: left;
}

#search.slim nav.search section#search_advanced {
    margin-left: 0;
    margin-right: 0;
    max-width: 1834px;
}

body > header.slim #search {
    display: none;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}
input[type="checkbox"].inform,
input[type="radio"].inform {
    position: absolute !important;
    left: -9999px !important;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
    position: relative;
    padding-left: 47px;
    cursor: pointer;
}

[type="checkbox"]:not(:checked).inform + label,
[type="checkbox"]:checked.inform + label,
[type="radio"]:not(:checked).inform + label,
[type="radio"]:checked.inform + label {
    white-space: normal;
    display: inline-block;
    height: auto;
    background-color: transparent;
    color: #4E4E4E;
    line-height: normal;
    font-weight: normal;
    padding: 4px 4px 4px 36px;
}

[type="checkbox"].slim:not(:checked) + label,
[type="checkbox"].slim:checked + label,
[type="radio"].slim:not(:checked) + label,
[type="radio"].slim:checked + label {
    padding-left: 41px;
    padding-right: 12px;
}

#search nav.search [type="checkbox"]:not(:checked) + label,
#search nav.search [type="checkbox"]:checked + label {
    width: 114px;
}

[type="checkbox"]:not(:checked) + label::before,
[type="checkbox"]:checked + label::before,
[type="radio"]:not(:checked) + label::before,
[type="radio"]:checked + label::before {
    content: '';
    position: absolute;
    left: 22px; top: 12px;
    width: 15px; height: 15px; /* dim. de la case */
    border: 1px solid #FFFFFF;
    background: transparent;
}

[type="checkbox"]:not(:checked).inform + label::before,
[type="checkbox"]:checked.inform + label::before,
[type="radio"]:not(:checked).inform + label::before,
[type="radio"]:checked.inform + label::before {
    top: 5px;
    left: 9px;
    border-color: #4E4E4E;
}

[type="checkbox"].slim:not(:checked) + label::before,
[type="checkbox"].slim:checked + label::before,
[type="radio"].slim:not(:checked) + label::before,
[type="radio"].slim:checked + label::before {
    left: 12px;
}

[type="radio"]:not(:checked) + label::before,
[type="radio"]:checked + label::before {
    border-radius: 8px;
}

[type="checkbox"]:focus:not(:checked) + label::before,
[type="checkbox"]:focus:checked + label::before,
[type="radio"]:focus:not(:checked) + label::before,
[type="radio"]:focus:checked + label::before {
    outline: 1px #FFFFFF dotted;
}

[type="checkbox"]:not(:checked) + label::after,
[type="checkbox"]:checked + label::after,
[type="radio"]:not(:checked) + label::after,
[type="radio"]:checked + label::after {
    content: url("/img/check_blanc.svg");
    position: absolute;
    top: 3px; left: 22px;
    font-size: 14px;
    color: #FFFFFF;
    transition: all .2s; /* on pr�voit une animation */
}

[type="checkbox"]:not(:checked).inform + label::after,
[type="checkbox"]:checked.inform + label::after,
[type="radio"]:not(:checked).inform + label::after,
[type="radio"]:checked.inform + label::after {
    content: url("/img/check_gris.svg");
    top: 5px;
    left: 9px;
}

[type="checkbox"].slim:not(:checked) + label::after,
[type="checkbox"].slim:checked + label::after,
[type="radio"].slim:not(:checked) + label::after,
[type="radio"].slim:checked + label::after {
    left: 13px;
}

[type="radio"]:not(:checked) + label::after,
[type="radio"]:checked + label::after {
    content: url("/img/dot_blanc.svg");
}

[type="checkbox"]:not(:checked) + label::after,
[type="radio"]:not(:checked) + label::after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise � l'�chelle � 0 */
}

[type="checkbox"]:checked + label::after,
[type="radio"]:checked + label::after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise � l'�chelle 1:1 */
}

[type="checkbox"]:not(:checked) + label,
[type="radio"]:not(:checked) + label {
    background: #8BA0AF;
}

[type="checkbox"]:checked + label,
[type="radio"]:checked + label {
    background: #0D85D2;
}

#search nav.search section#search_advanced {
    width: 100%;
    text-align: left;
    display: none;
}

#search.advanced nav.search section#search_advanced {
    display: inline-block;
}

#search nav.search section h2 {
    font-family: Roboto;
    font-size: 30px;
    color: #FFFFFF;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 30px;
}

body > main.icpe input[type="text"],
#search nav.search input[type="search"],
#search nav.search input[type="number"],
#search nav.search select,
nav.sort select {
    font-weight: bold;
    font-size: 12px;
    padding: 10px;
    border-radius: 0;
    border: none;
    color: var(--bleu_fonce);
    /*box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);*/
    height: 40px;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#search nav.search input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

#search nav.search select::-ms-expand,
nav.sort select::-ms-expand {
    display: none;
}

nav.sort select option {
    font-weight: bold;
    font-size: 12px;
    padding: 10px;
    border-radius: 0;
    border: none;
    color: var(--bleu_fonce);
    /*box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);*/
    height: 40px;
    vertical-align: top;
}

nav.sort select {
    cursor: pointer;
}

.select_checkbox {
    display: inline-block;
    vertical-align: top;
    height: 40px;
}

.select_checkbox:not(.mutable) label.select,
.select_checkbox:not(.mutable) select,
.select_checkbox:not(.mutable) input {
    width: inherit;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label {
    padding-left: 44px;
    padding-right: 47px;
    width: 100% !important;
}

.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:not(:checked) + label,
.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:checked + label {
    padding-right: 10px;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label:hover ,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label:hover {
    background-color: #0D85D2;
    color: #FFFFFF;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label::before,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label::before {
    left: auto;
    right: 22px;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label::after,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label::after {
    content: url("/img/check_bleu_electrique.svg");
    left: auto;
    right: 23px;
}

.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:not(:checked) + label::before,
.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:checked + label::before {
    right: auto;
    left: 13px;
}

.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:not(:checked) + label::after,
.select_checkbox.two_columns:not(.mutable) [type="checkbox"]:checked + label::after {
    content: url("/img/check_bleu_electrique.svg");
    right: auto;
    left: 14px;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label:hover::after,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label:hover::after {
    content: url("/img/check_blanc.svg");
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label {
    background: #FFFFFF;
    color: var(--bleu_fonce);
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label::before,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label::before {
    border-color: #0D85D2;
}

.select_checkbox:not(.mutable) [type="checkbox"]:not(:checked) + label:hover::before,
.select_checkbox:not(.mutable) [type="checkbox"]:checked + label:hover::before {
    border-color: #FFFFFF;
}

.select_checkbox [type="checkbox"]:not(:checked) + label[for="lot_entrepot"],
.select_checkbox [type="checkbox"]:checked + label[for="lot_entrepot"] {
    background-image: url("/img/categorie_entrepot.svg");
    background-repeat: no-repeat;
}

.select_checkbox [type="checkbox"]:not(:checked) + label:hover[for="lot_entrepot"],
.select_checkbox [type="checkbox"]:checked + label:hover[for="lot_entrepot"] {
    background-position: 0 -40px;
}

.select_checkbox [type="checkbox"]:not(:checked) + label[for="lot_bureaux"],
.select_checkbox [type="checkbox"]:checked + label[for="lot_bureaux"] {
    background-image: url("/img/categorie_bureaux.svg");
    background-repeat: no-repeat;
}

.select_checkbox [type="checkbox"]:not(:checked) + label:hover[for="lot_bureaux"],
.select_checkbox [type="checkbox"]:checked + label:hover[for="lot_bureaux"] {
    background-position: 0 -40px;
}

.select_checkbox [type="checkbox"]:not(:checked) + label[for="lot_commerce"],
.select_checkbox [type="checkbox"]:checked + label[for="lot_commerce"] {
    background-image: url("/img/categorie_commerce.svg");
    background-repeat: no-repeat;
}

.select_checkbox [type="checkbox"]:not(:checked) + label:hover[for="lot_commerce"],
.select_checkbox [type="checkbox"]:checked + label:hover[for="lot_commerce"] {
    background-position: 0 -40px;
}

.select_checkbox [type="checkbox"]:not(:checked) + label[for="lot_terrain"],
.select_checkbox [type="checkbox"]:checked + label[for="lot_terrain"] {
    background-image: url("/img/categorie_terrain.svg");
    background-repeat: no-repeat;
}

.select_checkbox [type="checkbox"]:not(:checked) + label:hover[for="lot_terrain"],
.select_checkbox [type="checkbox"]:checked + label:hover[for="lot_terrain"] {
    background-position: 0 -40px;
}

.select_checkbox:not(.mutable) .select_over {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
}

.select_checkbox.mutable .select_over {
    display: none;
}

.select_checkbox:not(.mutable) .checkboxes {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
    position: relative;
    z-index: 5;
    text-align: left;
}

.select_checkbox:not(.mutable) .checkboxes.toggled {
    display: none;
}

.select_checkbox.mutable .checkboxes {
    display: inline;
}

.select_checkbox.mutable .checkboxes div {
    display: inline;
}

.select_checkbox .checkboxes .label {
    color: #626F85;
    background-color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    padding-bottom: 5px;
}

.select_checkbox .checkboxes .label hr {
    width: 80%;
    height: 1px;
    background: #626F85;
    border: none;
    margin: 5px auto 5px;
}

.select_checkbox .checkboxes .dummy {
    background-color: #FFFFFF;
    height: 40px;
}

.select_checkbox.two_columns .checkboxes.left {
    width: 45%;
    float: left;
}

.select_checkbox.two_columns .checkboxes.right {
    width: 55%;
    float: right;
}

.select_checkbox:not(.mutable) select,
.select_checkbox:not(.mutable) input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background:  #FFFFFF;
    padding-left: 10px;
    padding-right: 22px;
}

.select_checkbox.mutable select {
    display: none;
}

.select_checkbox:not(.mutable) label.select {
    position: relative;
    padding: 0;
    background: transparent;
}

.select_checkbox.mutable label.select {
    display: none;
}

.select_checkbox:not(.mutable) label.select::after {
    content: '\25BC';
    color: #0D85D2;
    right: 5px;
    position: absolute;
    pointer-events: none;
}

.select_checkbox:not(.mutable):has(.checkboxes:not(.toggled)) label.select::after {
    content: '\25B2';
}

.select_checkbox:not(.mutable) label.select::before {
    content: '';
    background-color: #FFFFFF;
    width: 20px;
    height: 38px;
    top: 1px;
    right: 1px;
    position: absolute;
    pointer-events: none;
}

#lot_categorie_multi/*,
#lot_categorie_multi label.select,
#lot_categorie_multi select*/ {
    width: 283px;
}

#lot_categorie_multi select {
    background: url("/img/lot_categorie.svg") no-repeat #FFFFFF;
    padding-left: 40px !important;
}

#lot_categorie_multi .checkboxes {
    width: 283px;
}

#q {
    width: 300px;
}

#article_categorie_sous_categorie_multi {
    width: 400px;
    margin-left: 0 !important;
}

#biensubmit,
#articlesubmit,
#formsubmit,
#slider.decarbonation .button,
main.decarbonation .button {
    padding: 0 5px 3px 31px;
    background-color: #FDCB27;
    /*box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);*/
    color: var(--bleu_fonce);
    text-align: center;
    font-weight: bold;
    width: 129px;
    height: 40px;
    background-image: url("/img/button_search_bg.svg");
    background-repeat: no-repeat;
    background-position: 9px 0;
    background-size: 22px auto;
    border-radius: 0;
}

#formsubmit,
#slider.decarbonation .button,
main.decarbonation .button {
    background-image: none;
    padding: 0 22px;
    width: auto;
}

#slider.decarbonation .button,
main.decarbonation #formsubmit,
main.decarbonation .button {
    border-radius: 20px;
    text-transform: uppercase;
}

#biensubmit:hover,
#articlesubmit:hover,
#formsubmit:hover {
    background-color: #0D85D2 !important;
    color: #FFFFFF !important;
    background-position: 9px -34px;
}

#search nav.search section.mobile_search {
    background-color: #FDCB27;
    color: var(--bleu_fonce);
    width: 100%;
    text-align: center;
    display: none;
}

#bien_surface_label {
    width: 91px;
}

#bien_surface_min,
#bien_surface_max {
    width: 135px;
    text-align: right;
}

#bien_surface_min + #bien_surface_max {
    margin-left: 10px;
}

#search.full nav.search .select_checkbox,
#lot_vente + label,
#bien_surface_label,
#batiment_distance,
#biensubmit,
#articlesubmit,
#categorie_label,
#search.full nav.search label.select {
    margin-left: 21px;
}

#search.slim nav.search .select_checkbox,
#search.slim nav.search #lot_vente + label,
#search.slim nav.search #bien_surface_label,
#search.slim nav.search #batiment_distance,
#search.slim nav.search #biensubmit,
#search.slim nav.search #articlesubmit,
#search.slim nav.search label.select {
    margin-left: 10px;
}

#lot_mandat_multi,
#search.full nav.search .select_checkbox > label,
#search.slim nav.search .select_checkbox > label {
    margin-left: 0 !important;
}

#batiment_localisation_label {
    width: 114px;
}

#batiment_localisation {
    width: 420px;
    padding-right: 50px;
}

#user_position {
    background-image: url('/img/icon_position.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    width: 40px;
    height: 40px;
    right: 0px;
}

#batiment_distance {
    width: 240px;
    text-align: right;
}

#search.slim nav.search #batiment_distance {
    width: 229px;
}

#advanced_search {
    width: 40px;
    height: 40px;
    background: #8BA0AF;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 24px;
    padding: 0 0 4px 0;
    margin-left: 10px;
    vertical-align: top;
    border-radius: 0;
    display: none;
}

#fixed_search {
    position: absolute;
    top: 2px;
    right: 2px;
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 480px), screen and (min-width: 769px) and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
    #slider + section.search,
    #slider + script + section.search {
        margin-top: 50px;
    }

    #search {
        margin-top: 50px;
    }

    #slider #search,
    #slider.header.home #search {
        margin-top: 0;
        background-color: rgba(24, 66, 95, 1);
    }

    #search.slim.fixed {
        margin-top: 50px !important;
        position: static !important;
    }

    #search nav.search {
        padding-top: 10px;
    }

    #search nav.search section,
    #search.slim nav.search section,
    #search.slim nav.search section + section {
        width: 100%;
        text-align: center;
        margin-right: 0;
    }

    #search.slim nav.search #lot_vente + label,
    #search.slim nav.search section + section {
        margin-left: 0 !important;
    }

    #search nav.search section:first-child {
        height: 90px;
    }

    #search nav.search section {
        margin-bottom: 10px;
    }

    #search nav.search label span {
        display: none;
    }

    .select_checkbox.mutable label.select,
    .select_checkbox.mutable select,
    .select_checkbox.mutable input {
        width: inherit;
    }

    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label {
        padding-left: 44px;
        padding-right: 47px;
        width: 100%;
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label:hover ,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label:hover {
        background-color: #0D85D2;
        color: #FFFFFF;
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label::before,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label::before {
        left: auto;
        right: 22px;
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label::after,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label::after {
        content: url("/img/check_bleu_electrique.svg");
        left: auto;
        right: 23px;
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label:hover::after,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label:hover::after {
        content: url("/img/check_blanc.svg");
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label {
        background: #FFFFFF;
        color: var(--bleu_fonce);
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label::before,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label::before {
        border-color: #0D85D2;
    }
    
    #search nav.search .select_checkbox.mutable [type="checkbox"]:not(:checked) + label:hover::before,
    #search nav.search .select_checkbox.mutable [type="checkbox"]:checked + label:hover::before {
        border-color: #FFFFFF;
    }

    #search nav.search .select_checkbox.mutable .select_over {
        display: block;
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
    }

    #search nav.search .select_checkbox.mutable .checkboxes {
        display: none !important;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
        position: relative;
        z-index: 2;
        text-align: left;
    }

    #search nav.search .select_checkbox.mutable .checkboxes.toggled {
        display: block !important;
    }

    #search nav.search .select_checkbox.mutable .checkboxes div {
        display: block;
    }

    #search nav.search .select_checkbox.mutable select {
        display: inline;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("/img/lot_mandat.svg") no-repeat #FFFFFF;
        padding-left: 40px;
        padding-right: 22px;
    }

    #search nav.search .select_checkbox:not(.mutable) select {
        /*background: no-repeat #FFFFFF;
        padding-left: 10px;
        padding-right: 22px;*/
    }

    #search nav.search .select_checkbox.mutable select {
        display: inline;
    }

    #search nav.search .select_checkbox.mutable label.select {
        display: inline-block;
        position: relative;
        padding: 0;
        background: transparent;
    }

    #lot_mandat_multi/*,
    #lot_mandat_multi label.select,
    #lot_mandat_multi select*/ {
        width: 155px;
    }

    #lot_mandat_multi .checkboxes {
        width: 163px;
    }

    #lot_categorie_multi,
    #lot_categorie_multi label.select,
    #lot_categorie_multi select {
        width: 155px;
    }

    #search nav.search .select_checkbox.mutable label.select::after {
        content: '\25BC';
        color: #0D85D2;
        right: 5px;
        position: absolute;
        pointer-events: none;
    }

    #search nav.search .select_checkbox.mutable:has(.checkboxes.toggled) label.select::after {
        content: '\25B2';
    }

    #search nav.search .select_checkbox.mutable label.select::before {
        content: '';
        background-color: #FFFFFF;
        width: 20px;
        height: 38px;
        top: 1px;
        right: 1px;
        position: absolute;
        pointer-events: none;
    }

    #search.full nav.search .select_checkbox.mutable .checkboxes label {
        margin-left: 0 !important;
    }

    #lot_categorie_multi {
        margin-left: 10px !important;
    }

    #q_label,
    #bien_surface_label,
    #categorie_label {
        display: none;
    }

    #bien_surface_min {
        width: 155px;
        margin-left: 0;
    }

    #bien_surface_max {
        width: 155px;
    }

    #batiment_localisation_label {
        display: none;
    }

    #batiment_localisation {
        width: 200px;
    }

    #search.slim nav.search #batiment_distance,
    #batiment_distance {
        margin-left: 10px;
        width: 60px;
    }

    #biensubmit,
    #articlesubmit {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        background-position: 9px 0px;
    }

    #biensubmit:hover,
    #articlesubmit:hover {
        background-position: 9px -34px;
    }

    #biensubmit span,
    #articlesubmit span {
        display: none;
    }

    #q {
        width: 320px;
    }

    #article_categorie_sous_categorie_multi {
        width: 270px;
    }

    .select_checkbox.two_columns .checkboxes.left,
    .select_checkbox.two_columns .checkboxes.right {
        width: 100%;
    }

    .mobile_line_breaker {
        display: block;
        margin-top: 10px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
    #q {
        width: 250px;
    }

    #article_categorie_sous_categorie_multi {
        width: 220px;
    }

    .select_checkbox.two_columns .checkboxes.left,
    .select_checkbox.two_columns .checkboxes.right {
        width: 100%;
    }

    #articles_search #articlesubmit {
        margin-left: 10px;
        width: 40px;
        height: 40px;
    }

    #articles_search #articlesubmit span {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    #lot_categorie_multi .checkboxes {
        position: absolute;
        right: 0;
    }
}

@media screen and (min-width: 769px) {
    #search nav.search .select_checkbox.mutable .checkboxes {
        display: inline !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 945px) {
    #search nav.search section#search_advanced,
    #search nav.search section {
        width: 769px;
    }

    #search.fixed {
        top: 50px;
    }

    #lot_categorie_multi,
    #lot_categorie_multi label.select,
    #lot_categorie_multi select {
        width: 182px;
    }

    #bien_surface_label {
        width: 91px;
    }

    #bien_surface_min,
    #bien_surface_max {
        width: 97px;
    }

    #batiment_localisation_label {
        width: 114px;
    }

    #batiment_localisation {
        width: 320px;
    }

    #search.slim nav.search #batiment_distance,
    #batiment_distance {
        width: 163px;
    }
}

@media screen and (max-width: 945px) {
    #search.slim {
        margin-top: 50px;
    }

    #fixed_search {
        display: none;
    }
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1081px) {
    .select_checkbox.two_columns .checkboxes.left,
    .select_checkbox.two_columns .checkboxes.right {
        height: 273px;
        background-color: white;
    }
}

#brochure_encart {
    width:300px;
    height: 322px;
    background-image: url('/img/encart_plaquette.png');
}

#brochure_encart.fr {
    background-image: url('/img/encart_plaquette_fr.png');
}

#brochure_encart.en {
    background-image: url('/img/encart_plaquette_en.png');
}

#slider.publications #brochure_encart,
#slider.blog #brochure_encart {
    height: 260px;
    background-image: url('/img/encart_plaquette_260.png');
}

#slider.publications #brochure_encart.fr,
#slider.blog #brochure_encart.fr {
    background-image: url('/img/encart_plaquette_260_fr.png');
}

#slider.publications #brochure_encart.en,
#slider.blog #brochure_encart.en {
    background-image: url('/img/encart_plaquette_260_en.png');
}

#encart_inscription_dispolog {
    width:300px;
    height: 322px;
    background-image: url('/img/encart_inscription_dispolog.png');
}

#encart_inscription_dispolog.fr {
    background-image: url('/img/encart_inscription_dispolog_fr.png');
}

#encart_inscription_dispolog.en {
    background-image: url('/img/encart_inscription_dispolog_en.png');
}

@media screen and (min-width: 769px) and (max-width: 1890px) {
    #brochure_encart {
        height: 260px;
        background-image: url('/img/encart_plaquette_260.png');
    }

    #brochure_encart.fr {
        background-image: url('/img/encart_plaquette_260_fr.png');
    }

    #brochure_encart.en {
        background-image: url('/img/encart_plaquette_260_en.png');
    }

    #encart_inscription_dispolog {
        height: 260px;
        background-image: url('/img/encart_inscription_dispolog_260.png');
    }

    #encart_inscription_dispolog.fr {
        background-image: url('/img/encart_inscription_dispolog_260_fr.png');
    }

    #encart_inscription_dispolog.en {
        background-image: url('/img/encart_inscription_dispolog_260_en.png');
    }

    #search.slim nav.search section {
        float: none;
        margin: 0 auto 22px;
    }

    #search.slim nav.search section + section {
        margin-left: auto;
    }

    #search.slim nav.search section#search_advanced {
        max-width: 945px;
    }

    #search.slim nav.search .select_checkbox,
    #search.slim nav.search #lot_vente + label,
    #search.slim nav.search #bien_surface_label,
    #search.slim nav.search #batiment_distance,
    #search.slim nav.search #biensubmit,
    #search.slim nav.search #articlesubmit,
    #search.slim nav.search label.select {
        margin-left: 21px;
    }

    #lot_mandat_multi,
    #search.slim nav.search .select_checkbox > label {
        margin-left: 0 !important;
    }
}

@media screen and (min-width: 946px) and (max-width: 1890px) {
    #search.slim nav.search #batiment_distance {
        width: 240px;
    }
}

@media print {
    #search {
        display: none;
    }
}

/* ###################
       FIL D'ARIANE
   ###################*/

#breadcrumbs {
    background-color: #BFCFDB;
    width: 100%;
    box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.5);
    min-height: 31px;
}

#breadcrumbs:target::before {
    content: "";
    display: block;
    height: 50px;
    margin: -50px 0 0;
    background-color: var(--bleu_fonce);
}

body > header.slim #breadcrumbs {
    display: none;
}

#slider.header.home + #breadcrumbs,
#slider.header.franchise_expo_paris + #breadcrumbs {
    display: none;
}

#search.fixed + #breadcrumbs {
    margin-top: 229px;
}

#breadcrumbs nav {
    padding: 7px 0;
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 1140px) {
    #breadcrumbs nav {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.breadcrumb {
    display: inline;
}

.breadcrumb h1 {
    margin: 0;
    display: inline;
    font-size: inherit;
    font-weight: normal;
}

.breadcrumb a {
    color: #666666;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    #search.fixed + #breadcrumbs {
        margin-top: 0;
    }

    main.recherche_biens article.annonce.summary.image_on_left:target {
        /*background-clip: content-box;
        margin-top: -70px !important;
        padding-top: 70px;*/
        scroll-margin-top: 70px;
    }
}

@media screen and (min-width: 769px) and (max-width: 945px) {
    #search.fixed + #breadcrumbs {
        margin-top: 196px;
    }

    main.recherche_biens article.annonce.summary.image_on_left:target {
        /*background-clip: content-box;
        margin-top: -216px !important;
        padding-top: 216px;*/
        scroll-margin-top: 216px;
    }
}

@media screen and (min-width: 946px) and (max-width: 1890px) {
    #search.fixed + #breadcrumbs {
        margin-top: 291px;
    }

    main.recherche_biens article.annonce.summary.image_on_left:target {
        /*background-clip: content-box;
        margin-top: -216px !important;
        padding-top: 216px;*/
        scroll-margin-top: 216px;
    }
}

@media screen and (min-width: 1891px) {
    main.recherche_biens article.annonce.summary.image_on_left:target {
        /*background-clip: content-box;
        margin-top: -154px !important;
        padding-top: 154px;*/
        scroll-margin-top: 154px;
    }
}

/* ###################
       PIED DE PAGE
   ###################*/

body > footer {
    clear: both;
}

body > footer div.contact_bg {
    background-image: url("/img/bg_entreprise.jpg");
    background-position: center center;
    background-size: cover;
    height: 120px;
    position: relative;
    z-index: -1;
}

body > footer div.contact {
    background-color: rgba(13, 133, 210, 0.9);
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none;
}

body > footer section.contact {
    max-width: 1140px;
    margin: 0 auto;
    color: #FFFFFF;
    padding: 30px 0;
    position: relative;
}

body > footer section.contact h3 {
    font-size: 26px;
    font-weight: normal;
}

body > footer section.contact h4 {
    font-size: 13px;
    font-weight: normal;
}

body > footer section.contact div.button {
    position: absolute;
    text-transform: uppercase;
    padding: 5px;
    top: 39px;
    left: 600px;
    border: 2px solid #FFFFFF;
}

body > footer div.copyright {
    background-color: #2B2B2B;
}

body > footer section.copyright {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 0;
    line-height: 20px;
    color: #A0A9B1;
    overflow: auto;
}

@media screen and (max-width: 1140px) {
    body > footer section.copyright {
        padding-left: 5px;
        padding-right: 5px;
    }
}

body > footer section.copyright a {
    color: #A0A9B1;
}

body > footer #back2top {
    text-align: center;
    font-size: 13px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
    width: 60px;
    height: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    line-height: 85px;
    transition: margin-bottom 50ms;
}

body > footer #back2top:hover {
    background: #0D85D2;
}

/*body > footer #back2top a {
    text-decoration: none;
    color: #000000;
}*/

body > footer #back2top img {
    width: 30px;
}

#shade {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

#shade .content {
    margin: auto;
}

#immodvisor-seal-widget-iframe {
    top: unset !important;
    bottom: 40px !important;
}

@media screen and (max-width: 768px) {
    body > footer div.contact_bg {
        display: none;
    }
}

@media print {
    body > footer {
        display: none;
    }
}


/* ###################
     COOKIE CONSENT
   ###################*/

#cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;
}

#cookie-banner-message {
    color: #FFFFFF;
    background-color: var(--bleu_fonce);
    text-align: center;
    padding: 20px;
    font-size: 12px;
    border-top: 1px solid #eeeeee;
}

#cookie-banner-message a {
    color: #FDCB27;
}

#inform-and-ask {
    position: fixed;
    z-index: 11;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

#inform-and-consent {
    width: 600px;
    color: #000000;
    background: #FFFFFF;
    border: none;
    padding: 42px 30px 20px !important;
    text-align: center;
    position: fixed;
    top: 25%;
    left: 50%;
    margin-top: 0;
    margin-left: -300px;
    z-index: 100000;
    opacity: 1;
    font-size: 16px;
    line-height: 1.7em;
}

#inform-and-consent header {
    color: #FFFFFF;
    background-color: #0D85D2;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    font-size: 17px;
    font-weight: 700;
}

#inform-and-consent button {
    display: inline-block;
    color: var(--bleu_fonce);
    background-color: #FDCB27;
    height: 40px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 22px;
    vertical-align: top;
    text-align: left;
    white-space: nowrap;
}

#inform-and-consent button:hover {
    color: #FFFFFF;
    background-color: #0D85D2;
}

@media screen and (max-width: 768px) {
    #inform-and-consent {
        width: 95%;
        margin-left: 0;
        left: 2.5%;
        z-index: 100000;
    }
}

/* ###################
     BLOCS ANNONCES
   ###################*/

.gm-style-iw {
   width: 380px !important;
   top: 4px !important;
   left: 4px !important;
   border-radius: 0 0 0 0;
}

article.annonce.summary.highlight {
    outline: 5px solid #0D85D2;
    /*margin-top: -5px;*/
}

article.annonce.summary.highlight:empty {
    display: none;
}

article.annonce.summary.very.highlight {
    border-color: #FDCB27;
}

article.annonce.summary.image_on_top {
    margin-bottom: 20px;
}

article.gm-iw.annonce.summary.image_on_top {
    width: 360px;
    height: auto;
    min-height: 300px;
    margin-bottom: 0;
}

/*article.annonce.summary.highlight.image_on_top {
    margin-bottom: 15px;
    width: 370px;
}*/

article.annonce.summary.image_on_top div {
    position: relative;
}

article.annonce.summary.image_on_top section.image {
    width: 360px; height: 240px;
}

article.annonce.summary.image_on_top section.image.toutes {
    background-color: #0D85D2;
    background-image: url("/img/loupe_blanche.svg");
    background-position: center 50px;
    background-size: auto;
    background-repeat: no-repeat;
    color: #FFFFFF;
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
    /*padding: 170px 0 47px 0;*/
    font-weight: bold;
}

article.annonce.summary.image_on_top section.image.toutes.disabled {
    background-color: #8BA0AF;
}

article.annonce.summary.image_on_top section.image.toutes.gauche {
    background-image: url("/img/fleche_gauche.svg");
    background-size: 99px auto;
}

article.annonce.summary.image_on_top section.image.toutes.droite {
    background-image: url("/img/fleche_droite.svg");
    background-size: 99px auto;
}

article.annonce.summary.image_on_top section.image.toutes.liste {
    background-image: url("/img/liste_blanche.svg");
    background-size: 99px auto;
}

article.annonce.summary.image_on_top section.image.toutes section {
    position: absolute;
    top: 160px;
    width: 100%;
}

article.annonce.summary.image_on_top section.details {
    background-color: #F6FCFE;
    padding: 10px;
    overflow: auto;
    height: 113px;
}

article.annonce.summary.image_on_top.gm-iw section.details {
    height: auto;
}

article.annonce.summary.image_on_top section.details h3 {
    font-weight: normal;
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
}

article.annonce.summary.image_on_top section.details h3 a {
    color: inherit;
}

article.annonce.summary.image_on_top section.details h4,
article.annonce.summary.image_on_top section.details p {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    font-style: italic;
    /*color: #8BA0AF;*/
    color: var(--bleu_fonce);
    margin-bottom: 0;
}

article.annonce.summary.image_on_top section.details h4.reference,
article.annonce.summary.image_on_top section.details p.reference {
    margin-top: 0;
    font-style: normal;
}

article.annonce.summary.image_on_top section.details h4.reference small,
article.annonce.summary.image_on_top section.details p.reference small {
    margin-right: 1em;
}

/*@media screen and (max-width: 750px) {
    main.index section.annonces article {
        width: 100%;
        margin-left: 0 !important;
    }

    main.index section.annonces article section.image {
        width: 100%;
        padding-bottom: 66.6666%;
    }
}*/

article.annonce.summary.image_on_left {
    width: 945px; height: auto;
    min-height: 240px;
    overflow: visible;
    float: none;
}

article.annonce.summary.image_on_left + article.annonce.summary.image_on_left {
    /*margin-top: 20px;*/
}

article.annonce.summary.image_on_left section.image {
    width: 360px; height: 240px;
}

article.annonce.summary.image_on_left.highlight section.image {
    /*width: 355px;*/
}

article.annonce.summary.image_on_left section.image section.bandeau {
    color: #FFFFFF;
    text-transform: none;
    font-weight: normal;
}

article.annonce.summary.image_on_left section.text {
    width: 585px; height: auto;
    min-height: 240px;
    background-color: #D9E9F5;
}

article.annonce.summary.image_on_left.highlight section.text {
    /*width: 580px;*/
}

article.annonce.summary.image_on_left section.text h4,
article.annonce.summary.image_on_left section.text .h4 {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

article.annonce.summary.image_on_left section.text h4 span {
    color: #0D85D2;
}

article.annonce.summary.image_on_left section.text h5,
article.annonce.summary.image_on_left section.text .h5 {
    font-size: 14px;
    font-weight: normal;
}

article.annonce.summary.image_on_left section.text h5.italic {
    font-size: 13.4px;
}

article.annonce.summary.image_on_left section.text h5 + h5 {
    margin-top: -1em;
}

article.annonce.summary.image_on_left section.text h5.reference small {
    margin-right: 1em;
}

article.annonce.summary.image_on_left section.text p.commentaire {
    height: 6.8em;
    overflow: hidden;
}

article.annonce.summary.image_on_left section.text section.bandeau {
    color: #BFCFDB;
    background-color: transparent;
    width: 480px;
}

article.annonce.summary.image_on_left section.text a {
    color: inherit;
}

article.annonce.summary.image_on_left section.text section.bandeau.right {
    color: #FFFFFF;
    padding-right: 143px;
    background-color: var(--bleu_fonce) !important;
    width: 100%;
}

article.annonce.summary.image_on_left section.text section.bandeau.right span {
    display: block;
    position: absolute;
    color: #FFFFFF;
    background-color: #0D85D2;
    right: 0px;
    bottom: 0px;
    top: 0px;
    vertical-align: middle;
    padding: 9px 12px;
    text-transform: uppercase;
}

article.annonce.summary.image_on_left section.text section.bandeau.right span:hover {
    color: var(--bleu_fonce);
    background-color: #FDCB27;
}

main.recherche_biens article.annonce.summary.image_on_left {
    margin: 0 0 20px auto;
}

main.index article.annonce.summary.image_on_left {
    margin: 0 auto 20px;
}

article.annonce.summary.image_on_left section.text section.liste_lots {
    margin: 1em 0 2em 0;
}

article.annonce.summary.image_on_left footer a.button:first-child {
    margin-right: 5px;
}

article.annonce.summary.image_on_left footer a.button {
    margin-left: 0;
}

@media screen and (max-width: 945px) {
    article.annonce.summary.image_on_left {
        width: 360px; height: auto;
        min-height: 240px;
        overflow: auto;
        float: none;
    }

    article.annonce.summary.image_on_left.highlight section.image {
        width: 100%;
    }

    article.annonce.summary.image_on_left.highlight section.text,
    article.annonce.summary.image_on_left section.text {
        width: 100%; height: auto;
        min-height: 240px;
        background-color: #D9E9F5;
    }

    article.annonce.summary.image_on_left section.text section.bandeau:not(.right) {
        display: none;
    }

    article.annonce.summary.image_on_left section.text p.commentaire {
        margin-bottom: 35px;
        height: auto;
        overflow: visible;
    }

    article.annonce.summary.image_on_left footer {
        padding-left: 0;
    }

    article.annonce.summary.image_on_left footer a.button + a.button {
        margin-top: 5px;
    }

    section.programmes article.annonce.summary.image_on_left footer a.button + a.button {
        margin-top: 0;
    }

    article.annonce.summary.image_on_left footer a.button.file {
        width: 220px;
    }
}

@media screen and (max-width: 445px) {
    section.programmes article.annonce.summary.image_on_left footer a.button + a.button {
        margin-top: 5px;
    }
}

article.article.summary.image_on_top {
    margin-bottom: 40px;
}

article.article.summary.image_on_top section.image {
    width: 360px;
    height: 240px;
}

article.article.summary.image_on_top.slim section.image {
    height: 100px;
}

article.article.summary.image_on_top h3 {
    margin-top: 10px;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    text-transform: none;
}

article.article.summary.image_on_top h3 a {
    color: inherit;
}

article.article.summary.image_on_top h4,
article.article.summary.image_on_top .h4 {
    font-weight: normal;
    font-size: 16px;
    text-align: left;
}

article.article.summary.image_on_top ul li a {
    color: inherit;
}

article.article.summary.image_on_top section.details {
    background-color: #D9E9F5;
    padding: 5px;
    margin: 10px 0;
}

.lightblue article.article.summary.image_on_top section.details {
    background-color: #FFFFFF;
}

article.article.summary.image_on_top section.details + section.details {
    margin-top: -10px;
}

article.article.summary.image_on_top section.details img {
    vertical-align: bottom;
    margin-right: 3px;
}

article.list a {
    color: #4E4E4E;
}


/* ###################
          INDEX
   ###################*/

main.index div {
    clear: both;
}

main section.rubrique {
    max-width: 1140px;
    margin: 0 auto;
    padding: 70px 0 30px;
    clear: both;
    page-break-inside: avoid;
}

main section.rubrique :target {
    scroll-margin-top: 120px;
}

main.recherche_biens > header h3,
main.contact section > header h3,
main.contact > header h3,
main section.rubrique header h3,
main section.rubrique header .h3 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;
}

main.contact section > header h4,
main.contact > header h4,
main section.rubrique header h4,
main section.rubrique header .h4 {
    color: #4e4e4e;
    font-size: 31px;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 40px;
}

main section.rubrique header hr {
    width: 68px;
    height: 1px;
    background: #BFCFDB;
    border: none;
    margin: 30px auto 35px;
}

main section.rubrique header p {
    font-size: 15px;
    margin-bottom: 1em;
    text-align: justify;
}

main section.rubrique header ul {
    font-size: 15px;
    margin: 0 0 1em 0;
    padding-left: 0;
    list-style: square;
}

main section.rubrique header li {
    margin-left: 40px;
}

main section.rubrique footer {
    clear: both;
}

main section.rubrique footer a.button + a.button {
    margin-left: 10px;
}

main section.rubrique article {
    width: 360px;
    float: left;
}

main.commerce section.rubrique.metiers article {
    width: 555px;
}

main section.rubrique article + article {
    margin-left: 30px;
}

main section.rubrique article + article.highlight,
main section.rubrique article.highlight + article {
    /*margin-left: 25px;*/
}

main section.rubrique article.highlight + article.highlight {
    /*margin-left: 20px;*/
}

main:not(.commerce) section.rubrique:not(.metiers) article + article:nth-of-type(3n+1),
main:not(.commerce) section.rubrique:not(.metiers) article:nth-of-type(3n+1),
main.entreprise section.rubrique.metiers article + article:nth-of-type(3n+1),
main.entreprise section.rubrique.metiers article:nth-of-type(3n+1),
main.commerce section.rubrique.annonces article + article:nth-of-type(3n+1),
main.commerce section.rubrique.annonces article:nth-of-type(3n+1) {
    margin-left: 0;
}

main.commerce section.rubrique.metiers article + article:nth-of-type(2n+1),
main.commerce section.rubrique.metiers article:nth-of-type(2n+1) {
    margin-left: 0;
}

main section.rubrique article.highlight:nth-of-type(3n+1),
main section.rubrique article + article.highlight:nth-of-type(3n+1) {
    /*margin-left: -5px;*/
}

main section.rubrique article.highlight:nth-of-type(3n),
main section.rubrique article + article.highlight:nth-of-type(3n) {
    /*margin-right: -5px;*/
}

main section.rubrique article h2 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;
}

main section.rubrique article div.overflow {
    overflow: hidden;
    height: 499px;
    position: relative;
}

/*main.index section article div.overflow:::after {
    display: block;
    position: absolute;
    background: linear-gradient(to bottom, transparent, #FFFFFF);
    bottom: 0;
    content: "";
    width: 100%;
    height: 1em;
}*/

main section.rubrique article div.image {
}

main section.rubrique article div.image img {
    width: 360px;
    vertical-align: top;
}

main section.rubrique article ul,
main section.rubrique article ol {
    display: block;
    margin: 0 0 1em 0;
    padding-left: 0;
}

main section.rubrique article ul li,
main section.rubrique article ol li {
    display: block;
    overflow: hidden;
    margin-left: 0;
}

main section.rubrique article ul.list li,
main section.rubrique article ol.list li {
    display: list-item;
    overflow: visible;
    font-size: inherit;
    margin-left: 1em;
}

main section.rubrique article ul li div,
main section.rubrique article ol li div {
    text-align: justify;
    line-height: 17px;
    /*overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;*/
}

main section.rubrique article ul li > div + div,
main section.rubrique article ol li > div + div {
    margin-top: 5px;
}

main section.rubrique article ul hr,
main section.rubrique article ol hr {
    width: 90%;
    height: 1px;
    background: #BFCFDB;
    border: none;
    margin: 0 auto;
}

main section.rubrique article ul hr + li,
main section.rubrique article ol hr + li {
    margin-top: 1em;
}

main section.rubrique article ul li + hr,
main section.rubrique article ol li + hr {
    margin-top: 1em;
}

main.index div.shadow {
    box-shadow: -2px 10px 10px rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: relative;
}

@media screen and (max-width: 1140px) {
    main section.rubrique header {
        padding-left: 5px;
        padding-right: 5px;
    }
}

/**********************/

main div.darkblue {
    background-color: var(--bleu_fonce);
    height: 245px;
}

main div.darkblue + section.darkblue {
    margin-top: -245px;
}

main div.lightblue {
    background-color: #D9E9F5;
}

main section.darkblue header h3,
main section.darkblue header .h3,
main section.darkblue article h2,
main section.darkblue article .h2 {
    color: #FDCB27;
}

main section.darkblue header h4,
main section.darkblue header .h4,
main section.darkblue header p {
    color: #FFFFFF;
}

main section.white {
    background-color: transparent;
}

main.recherche_biens > header h3,
main section.white header h3,
main section.lightblue header h3 {
    color: #0D85D2;
}

main.recherche_biens > header h4 {
    text-align: center;
}

main section.white article h2,
main section.lightblue article h2 {
    color: #000000;
}

main section.white header h4,
main section.lightblue header h4 {
    color: #4E4E4E;
}

section.rubrique.darkblue.fleche,
section.rubrique.lightblue.fleche {
    background-image: url("/img/fleche_rubrique_white.svg");
    background-repeat: no-repeat;
}

div.lightblue + div.darkblue + section.rubrique.darkblue.fleche,
section.rubrique.white.fleche {
    background-image: url("/img/fleche_rubrique_lightblue.svg");
    background-repeat: no-repeat;
}

section.fleche.droite {
    background-position: top right;
}

section.fleche.gauche {
    background-position: top left;
}

/**********************/

main.index section.construction {
    height: 404px;
    position: relative;
    background-image: url("/img/play_blanc.svg"), url("/img/home_construction.jpg");
    background-repeat: no-repeat;
    background-position: 100px 96px, 0 0;
    margin-bottom: 80px;
}

main.index section.construction a {
    padding: 202px 50%;
    position: absolute;
    top: 0;
}

main.index section.construction section {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 16px 26px;
    position: absolute;
    left: 425px;
}

main.index section.construction section.electric_bg {
    font-weight: bold;
    font-size: 30px;
    top: 113px;
    line-height: 28px;
}

main.index section.construction section.white_bg {
    font-size: 19px;
    top: 185px;
    line-height: 19px;
}

main.index section.construction section.yellow_bg {
    font-weight: bold;
    font-size: 17px;
    top: 248px;
    padding: 14px 26px;
}

/**********************/

main.index div.presentation.darkblue {
    /*background-image: linear-gradient(0deg, rgba(24, 66, 95, 0.9), rgba(24, 66, 95, 0.9)), url("/img/home_presentation.jpg");*/
    background-image: linear-gradient(0deg, hsla(205, 100%, 20%, 0.7), hsla(205, 100%, 20%, 0.7)), url("/img/home_presentation.jpg");
    background-position: center center, center center;
    background-size: auto, cover;
    height: 499px;
}

main.index section.presentation.darkblue {
    margin-top: -499px;
}

main.index section.presentation header h4 span {
    color: #FDCB27;
}

main.index section.presentation header hr {
    width: 100%;
}

main.index section.presentation article {
    text-align: justify;
    margin-bottom: 30px;
}

main.index section.presentation article section.image {
    width: 360px;
    height: 240px;
}

main.index section.presentation article h3 {
    font-weight: normal;
    font-size: 31px;
    padding: 25px 0 11px;
    text-transform: none;
    color: #0D85D2;
    border-bottom: 1px solid #8BA0AF;
    margin-bottom: 35px;
}

@media screen and (max-width: 750px) {
    main.index section.presentation article {
        width: 100%;
    }

    main.index section.presentation article section.image {
        width: 100%;
        padding-bottom: 66.6666%;
    }
}

/**********************/

main.index div.publications.darkblue {
    /*background-image: linear-gradient(0deg, rgba(24, 66, 95, 0.9), rgba(24, 66, 95, 0.9)), url("/img/home_presentation.jpg");*/
    background-image: linear-gradient(0deg, hsla(205, 100%, 20%, 0.7), hsla(205, 100%, 20%, 0.7)), url("/img/home_publications.jpg");
    background-position: center center, center center;
    background-size: auto, cover;
    height: 333px;
}

main.index section.publications.darkblue {
    margin-top: -333px;
}

@media screen and (max-width: 750px) {
    article.article.summary.image_on_top {
        width: 100%;
    }

    article.article.summary.image_on_top section.image {
        width: 100%;
        padding-bottom: 66.6666%;
    }
}

/**********************/

main.decarbonation div.decarbonation.green {
    background-image: url("/img/home_decarbonation.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 610px;
}

main.decarbonation section.decarbonation.green {
    margin-top: -610px;
    padding-top: 0;
    height: 610px;
}

main.decarbonation section.decarbonation.green header {
    font-weight: bold;
    font-size: 26px;
    color: #FFFFFF;
    padding-left: 10px;
    padding-right: 10px;
}

main.decarbonation section.decarbonation.green header h4 {
    margin-top: 1.5em;
    margin-bottom: 1em;
}

main.decarbonation section.decarbonation.green article {
    font-size: 18px;
    color: #FFFFFF;
}

main.decarbonation section.decarbonation.green article:first-of-type {
    /*width: calc(42% - 15px);*/
}

main.decarbonation section.decarbonation.green article:last-of-type {
    font-size: 26px;
    font-weight: bold;
    /*width: calc(58% - 15px);*/
}

main.decarbonation section.decarbonation.green article:last-child span {
    text-transform: uppercase;
    color: #FDCB27;
}

main.decarbonation section.decarbonation.green article table.green,
main.decarbonation section.decarbonation.green article div.table.white {
    border-radius: 5px;
    box-shadow: 0 0 57px 0 rgba(181, 97, 184, 0.2);
    padding: 15px;
}

main.decarbonation section.decarbonation.green article table.green {
    background-color: #5AB850;
    font-size: 16px;
    min-width: 450px;
}

main.decarbonation section.decarbonation.green article table.green th {
    text-align: left;
    font-size: 18px;
}

main.decarbonation section.decarbonation.green article table.green th,
main.decarbonation section.decarbonation.green article table.green td {
    padding: 4px 0;
}

main.decarbonation section.decarbonation.green article table.green th div {
    display: inline-block;
    width: 50%;
}

main.decarbonation section.decarbonation.green article table.green td.filer {
    width: 70px;
}

main.decarbonation section.decarbonation.green article table.green td.number {
    text-align: right;
}

main.decarbonation section.decarbonation.green article table.green hr {
    background-color: #FFFFFF;
    border: none;
    height: 1px;
}

main.decarbonation section.decarbonation.green article table.green th hr {
    height: 2px;
}

main.decarbonation section.decarbonation.green article div.table.white {
    background-color: #FFFFFF;
    margin-bottom: 1.2em;
    width: 450px;
}

main.decarbonation section.decarbonation.green article div.table.white table {
    color: #5AB850;
    font-size: 16px;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

main.decarbonation section.decarbonation.green article table.white th {
    text-align: left;
    border: 1px solid #5AB850;
    border-collapse: collapse;
    padding: 10px 5px;
}

main.decarbonation section.decarbonation.green article table.white td {
    border: 1px solid #5AB850;
    border-collapse: collapse;
    padding: 20px 5px;
    text-align: center;
    font-weight: normal;
}

main.decarbonation section.decarbonation.green article table.white tr:last-child td {
    padding-bottom: 0;
}

main.decarbonation section.decarbonation.green article table.white tr {
    height: 59px;
}

main.decarbonation section.decarbonation.green article table.white th:first-child {
    border-left: none;
}

main.decarbonation section.decarbonation.green article table.white td:last-child,
main.decarbonation section.decarbonation.green article table.white th:last-child {
    border-right: none;
}

main.decarbonation section.decarbonation.green article table.white tr:first-of-type td,
main.decarbonation section.decarbonation.green article table.white tr:first-of-type th {
    border-top: none;
}

main.decarbonation section.decarbonation.green article table.white tr:last-of-type td,
main.decarbonation section.decarbonation.green article table.white tr:last-of-type th {
    border-bottom: none;
}

main.decarbonation section.decarbonation.green article table.white tbody tr:last-of-type {
    vertical-align: top;
}

main.decarbonation section.decarbonation.green article table.white thead tr th {
    border-bottom: 2px solid #5AB850 !important;
    vertical-align: middle;
    text-align: center;
}

main.decarbonation section.decarbonation.green article table.white thead tr th:first-child {
    text-align: left;
}

/*main.decarbonation section.decarbonation.green article table.white td:last-child {
    text-align: right;
}*/

main.decarbonation section.decarbonation.green article table.white td span {
    display: inline-block;
    background-color: #FDCB27;
    color: var(--bleu_fonce);
    border-radius: 5px;
    font-size: 22px;
    font-weight: bold;
    padding: 5px;
    margin-top: 0.7em;
}

main.decarbonation section.decarbonation.green article.white {
    color: #5AB850;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    line-height: 1.6em;
}

main.decarbonation section.decarbonation.green .headline {
    max-width: 450px;
}

main.decarbonation section.decarbonation.green .headline span b {
    text-transform: lowercase;
    font-weight: bold;
}

main.decarbonation section.rubrique.white {
    padding-top: 0;
}

main.decarbonation section.rubrique.white .headline {
    font-weight: bold;
    font-size: 26px;
    color: var(--bleu_fonce);
}

main.decarbonation section.rubrique.white .headline span {
    font-size: 26px;
    color: #5AB850;
    text-transform: uppercase;
    /*display: block;*/
}

main.decarbonation section.rubrique.white article h4 {
    font-weight: bold;
    font-size: 26px;
    color: var(--bleu_fonce);
    margin-top: 2em;
}

main.decarbonation section.rubrique.white article p,
main.decarbonation section.rubrique.white article ul {
    font-size: 18px;
    /*font-weight: bold;*/
    color: #666666;
}

main.decarbonation section.rubrique.white article ul.grey {
}

main.decarbonation section.rubrique.white article ul.green {
    color: #5AB850;
    font-weight: bold;
}

main.decarbonation section.rubrique.white article ul.grey li {
    background-image: url("/img/leef_grey.png");
    background-repeat: no-repeat;
    background-position: 0 5px;
    padding-left: 34px;
}

main.decarbonation section.rubrique.white article ul.green li {
    background-image: url("/img/leef_green.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    padding-left: 35px;
    line-height: 26px;
}

main.decarbonation section.rubrique.white article p + ul {
    margin-top: 1em;
}
main.decarbonation section.rubrique.white article ul.grey li + li {
    margin-top: 2em;
}

main.decarbonation section.rubrique.white article > div {
    margin-top: 2em;
}

main.decarbonation section.rubrique.white article div.center {
    text-align: left;
}

main.decarbonation section.rubrique.white article div.center a + a {
    margin-left: 2em;
}

main.decarbonation section.rubrique.white.formulaire header {
    font-weight: bold;
    font-size: 26px;
    color: var(--bleu_fonce);
    margin-bottom: 2em;
}

main.decarbonation section.rubrique.white.formulaire header {
    margin-top: 2em;
    margin-bottom: 0;
}

main.decarbonation section.rubrique.white.formulaire header div {
    color: #4AA840;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 42px;
}

@media screen and (max-width: 768px) {
    main.decarbonation section.rubrique article {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    #slider.header.decarbonation,
    #slider.header.decarbonation .content {
        display: block;
    }

    #slider.header.decarbonation {
        height: 710px;
    }

    #slider.header.decarbonation p {
        padding-top: 400px;
    }
    #slider.header.decarbonation .content div {
        padding-left: 5px;
        padding-right: 5px;
    }

    main.decarbonation section article {
        width: 100%;
    }

    main.decarbonation section article img {
        width: 100%;
    }

    main.decarbonation section#solutions article.image {
        display: none;
    }

    main.decarbonation div.decarbonation.green {
        height: 1310px;
    }

    main.decarbonation section.decarbonation.green {
        margin-top: -1310px;
        height: 1310px;
    }

    main.decarbonation section.decarbonation.green article table.green {
        min-width: unset;
    }

    main.decarbonation section.decarbonation.green article table.green th div {
        display: block;
        width: 100%;
    }

    main.decarbonation section.decarbonation.green article div.table.white {
        margin-top: 1em;
        width: 100%;
    }

    main.decarbonation section.rubrique.white article div.center {
        text-align: center;
    }

    #slider.header.decarbonation div a + a,
    main.decarbonation section.rubrique.white article div.center a + a {
        margin-top: 1em;
        margin-left: 0;
    }

    main.decarbonation section.decarbonation.green article table.green,
    main.decarbonation section.decarbonation.green article div.table.white table {
        /*font-size: 10px;*/
    }

    main.decarbonation section.decarbonation.green article table.green th {
        /*font-size: 12px;*/
    }

    main.decarbonation section.decarbonation.green article table.white td span {
        /*font-size: 11px;*/
        padding: 3px;
        margin-left: 0.2em;
    }

    main.decarbonation section.rubrique.white.formulaire header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 418px) {
    #slider.header.decarbonation div a,
    main.decarbonation section.rubrique.white article div.center a {
        display: block;
        width: max-content;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media screen and (min-width: 419px) and (max-width: 768px) {
    #slider.header.decarbonation {
        height: 610px;
    }

    #slider.header.decarbonation p {
        padding-top: 300px;
    }

    #slider.header.decarbonation div a + a,
    main.decarbonation section.rubrique.white article div.center a + a {
        margin-top: 0;
        margin-left: 1em;
    }

    main.decarbonation section.decarbonation.green article table.green,
    main.decarbonation section.decarbonation.green article div.table.white table {
        /*font-size: 12px;*/
    }

    main.decarbonation section.decarbonation.green article table.green th {
        /*font-size: 14px;*/
    }

    main.decarbonation section.decarbonation.green article table.white td span {
        /*font-size: 13px;*/
    }
}

/**********************/
main.index section.transactions article,
main.index section.immobiliere article,
main.publications section.transactions article,
main.publications section.immobiliere article,
main.decarbonation section.rubrique article {
    width: 555px;
}

/**********************/

main.index div.projets.darkblue {
    /*background-image: linear-gradient(0deg, rgba(24, 66, 95, 0.9), rgba(24, 66, 95, 0.9)), url("/img/home_presentation.jpg");*/
    background-image: linear-gradient(0deg, hsla(205, 100%, 20%, 0.7), hsla(205, 100%, 20%, 0.7)), url("/img/construction_projets.jpg");
    background-position: center center, center center;
    background-size: auto, cover;
    height: 333px;
}

main.index section.projets.darkblue {
    margin-top: -333px;
}

/**********************/

main.index div.programmes.darkblue {
    /*background-image: linear-gradient(0deg, rgba(24, 66, 95, 0.9), rgba(24, 66, 95, 0.9)), url("/img/home_presentation.jpg");*/
    background-image: linear-gradient(0deg, hsla(205, 100%, 20%, 0.7), hsla(205, 100%, 20%, 0.7)), url("/img/home_commerce.jpg");
    background-position: center center, center 90%;
    background-size: auto, cover;
    height: 333px;
}

main.index section.programmes.darkblue {
    margin-top: -333px;
}

main.index section.programmes.darkblue footer {
    width: 945px;
    margin: 0 auto;
}

@media screen and (max-width: 945px) {
    main.index section.programmes.darkblue footer {
        width: 100%;
    }
}

/**********************/

main.index div.metiers.darkblue {
    background-image: linear-gradient(0deg, hsla(205, 100%, 20%, 0.7), hsla(205, 100%, 20%, 0.7)), url("/img/home_publications.jpg");
    background-position: center center, center center;
    background-size: auto, cover;
    height: 245px;
}

/**********************/

main.index section.metiers article {
    height: 390px;
    margin-bottom: 30px;
    text-align: center;
}

main.index.commerce section.metiers article {
    height: auto;
}

a.button.pdf_dl,
a.button.folder,
a.button.file,
a.button.star,
a.button.prev,
a.button.next,
a.button.list,
main.index.commerce section.metiers footer a.button {
    background-repeat: no-repeat;
    padding-left: 56px;
    min-width: 220px;
    text-align: center;
}

a.button.pdf_dl,
main.index.commerce section.metiers footer a.button {
    background-image: url('/img/button_pdf_bg.svg');
    background-position: 15px 9px;
}

a.button.folder {
    background-image: url('/img/button_folder_bg.svg');
    background-position: 15px 9px;
}

a.button.file {
    background-image: url('/img/button_file_bg.svg');
    background-position: 15px 8px;
    width: auto;
}

a.button.star {
    background-image: url('/img/button_star_bg.svg');
    background-position: 15px 8px;
    min-width: auto;
    width: auto;
}

a.button.prev {
    background-image: url('/img/button_fleche_gauche_bg.svg');
    background-size: 30px;
    background-position: 2px 5px;
    padding-left: 40px;
    min-width: unset;
}

a.button.prev:empty {
    background-position: 6px 5px;
}

a.button.next {
    background-image: url('/img/button_fleche_droite_bg.svg');
    background-size: 30px;
    background-position: calc(100% - 2px) 5px;
    padding-left: 22px;
    padding-right: 40px;
    min-width: unset;
}

a.button.next:empty {
    background-position: calc(100% - 6px) 5px;
}

a.button.list {
    background-image: url('/img/button_liste_bg.svg');
    background-size: 40px;
    min-width: unset;
}

a.button.pdf_dl:hover,
main.index.commerce section.metiers footer a.button:hover {
    background-position: 15px -25px;
}

a.button.folder:hover {
    background-position: 15px -27px;
}

a.button.file:hover {
    background-position: 15px -31px;
}

a.button.star:hover {
    background-position: 15px -31px;
}

a.button.prev:hover {
    background-position: 2px -33px;
}

a.button.prev:empty:hover {
    background-position: 6px -33px;
}

a.button.next:hover {
    background-position: calc(100% - 2px) -33px;
}

a.button.next:empty:hover {
    background-position: calc(100% - 6px) -33px;
}

a.button.list:hover {
    background-position: 0 -40px;
}

main.index section.metiers article ul {
    text-align: left;
    font-size: 15px;
}

main.index section.metiers article img {
    width: 100px;
}

main.index section.metiers article h3 {
    text-align: left;
    text-transform: uppercase;
    font-size: 17px;
}

main.index section.metiers article h3:empty {
    height: 22px;
}

main.index section.metiers article h4 {
    text-align: left;
    font-size: 15px;
}

/**********************/

main.index section.investissement div.texte,
main.index section.recrutement div.texte,
main.index section.tri_van div.texte {
    width: 45%;
    float: left;
    clear: none;
}

main.contact section.flex article dl,
main.contact .map_element .agence dl,
main.index section.investissement div.texte dl,
main.index section.recrutement div.texte dl,
main.index section.tri_van div.texte dl  {
    margin-top: 1em;
}

main.contact section.flex article dd span,
main.contact .map_element .agence dd span,
main.index section.investissement div.texte dd span,
main.index section.recrutement div.texte dd span,
main.index section.tri_van div.texte dd span {
    color: #0D85D2;
}

main.contact section.flex article dt,
main.contact .map_element div + div dt,
main.index section.investissement div.texte dt,
main.index section.recrutement div.texte dt,
main.index section.tri_van div.texte dt {
    float: left;
    width: 25px;
    padding-top: 2px;
    text-align: left;
    clear: both;
}

main.contact section.flex article dd,
main.contact .map_element .agence dd,
main.index section.investissement div.texte dd,
main.index section.recrutement div.texte dd,
main.index section.tri_van div.texte dd {
    margin-left: 25px;
}

main.index section.investissement div.formulaire,
main.index section.recrutement div.formulaire,
main.index section.tri_van div.formulaire {
    width: 45%;
    float: right;
    clear: none;
}

main.article div.formulaire,
main.article div.formulaire dl + dl,
main.contact section.formulaire dl + dl,
main.index section.investissement div.formulaire dl + dl,
main.index section.recrutement div.formulaire dl + dl,
main.index section.tri_van div.formulaire dl + dl,
main.decarbonation section.formulaire div.formulaire dl + dl {
    margin-top: 0.5em;
}

main.article div.formulaire {
    width: 320px;
}

main.contact section.formulaire dl.left,
main.index section.investissement div.formulaire dl.left,
main.index section.recrutement div.formulaire dl.left,
main.index section.tri_van div.formulaire dl.left {
    margin-bottom: 0.5em;
    width: 45%;
    float: left;
}

main.contact section.formulaire dl.middle,
main.index section.investissement div.formulaire dl.middle,
main.index section.recrutement div.formulaire dl.middle,
main.index section.tri_van div.formulaire dl.middle {
    margin-bottom: 0.5em;
    width: 10%;
    float: left;
    text-align: center;
}

main.contact section.formulaire dl.right,
main.index section.investissement div.formulaire dl.right,
main.index section.recrutement div.formulaire dl.right,
main.index section.tri_van div.formulaire dl.right {
    margin-bottom: 0.5em;
    width: 45%;
    float: right;
}

main.contact section.formulaire dl.left + dl:not(.right):not(.middle),
main.contact section.formulaire dl.right + dl:not(.left),
main.index section.investissement div.formulaire dl.left + dl:not(.right):not(.middle),
main.index section.investissement div.formulaire dl.right + dl:not(.left),
main.index section.recrutement div.formulaire dl.left + dl:not(.right):not(.middle),
main.index section.recrutement div.formulaire dl.right + dl:not(.left),
main.index section.tri_van div.formulaire dl.left + dl:not(.right):not(.middle),
main.index section.tri_van div.formulaire dl.right + dl:not(.left) {
    margin-top: 0.5em;
    clear: both;
}

main.article div.formulaire dt:empty,
main.contact section.formulaire dt:empty,
main.index section.investissement div.formulaire dt:empty,
main.index section.recrutement div.formulaire dt:empty,
main.index section.tri_van div.formulaire dt:empty {
    margin-top: 1em;
}


main.article div.formulaire dd,
main.contact section.formulaire dd,
main.index section.investissement div.formulaire dd,
main.index section.recrutement div.formulaire dd,
main.index section.tri_van div.formulaire dd,
main.decarbonation section.formulaire div.formulaire dd {
    margin-top: 4px;
}

main.article div.formulaire dd input[type="text"],
main.article div.formulaire dd input[type="email"],
main.article div.formulaire dd input[type="tel"],
main.contact section.formulaire dd input[type="text"],
main.contact section.formulaire dd input[type="email"],
main.contact section.formulaire dd input[type="tel"],
main.contact section.formulaire dd textarea,
main.index section.investissement div.formulaire dd input[type="text"],
main.index section.investissement div.formulaire dd input[type="email"],
main.index section.investissement div.formulaire dd input[type="tel"],
main.index section.investissement div.formulaire dd textarea,
main.index section.recrutement div.formulaire dd input[type="text"],
main.index section.recrutement div.formulaire dd input[type="email"],
main.index section.recrutement div.formulaire dd input[type="tel"],
main.index section.recrutement div.formulaire dd textarea,
main.index section.tri_van div.formulaire dd input[type="text"],
main.index section.tri_van div.formulaire dd input[type="email"],
main.index section.tri_van div.formulaire dd input[type="tel"],
main.index section.tri_van div.formulaire dd textarea,
main.decarbonation section.formulaire div.formulaire dd input {
    width: 100%;
}

main.index section.tri_van div.formulaire section {
    font-weight: bold;
    font-size: 17px;
}

main.index section.tri_van div.formulaire section + section {
    margin-top: 1.5em;
}

main.index section.tri_van div.formulaire section span {
    font-weight: normal;
    font-size: 17px;
}

main.index section.tri_van div.formulaire section div {
    font-weight: normal;
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    main.index section.investissement div.texte,
    main.index section.recrutement div.texte,
    main.index section.tri_van div.texte,
    main.index section.investissement div.formulaire,
    main.index section.recrutement div.formulaire,
    main.index section.tri_van div.formulaire {
        width: 100%;
        float: none;
        padding-left: 5px;
        padding-right: 5px;
    }

    main.index section.investissement div.texte,
    main.index section.recrutement div.texte,
    main.index section.tri_van div.texte {
        margin-bottom: 2em;
    }
}

/**********************/

main.index section.chiffres article,
main.index section.partenaires article {
    font-size: 15px;
    text-align: center;
    margin: 0 0 2em 0;
}

main.index section.partenaires article {
    width: 20%;
}

main.index section.chiffres article {
    width: 33%;
    width: calc(100% / 3);
}

main.index section.chiffres article img,
main.index section.partenaires article img {
    height: 90px;
}

main.index section.chiffres article hr,
main.index section.partenaires article hr {
    width: 120px;
    margin: 1em auto;
    height: 2px;
    background: #BFCFDB;
    border: none;
}

main.index section.chiffres article span {
    font-weight: bold;
    font-size: 19px;
}

/**********************/

main.index.commerce section.references article {
    position: relative;
    padding-bottom: 110px;
}

main.index.commerce section.references article footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

main.index.commerce section.references article.new {
    width: 720px;
}

main.index.commerce section.references article.new section.image {
    width: 360px;
    height: 506px;
    background-size: 100%;
    background-repeat: no-repeat;
}

main.index.commerce section.references article.new section.text {
    width: 360px;
    padding: 15px;
    text-align: center;
    background-color: #FFFFFF;
}

main.index.commerce section.references article.new section.text div {
    font-size: 26px;
    text-align: justify;
    margin-bottom: 2em;
}

@media screen and (max-width: 649px) {
    main.index.commerce section.references article.new section.text {
        width: 100%;
    }
}

/**********************/

@media screen and (min-width: 650px) and (max-width: 1079px) {
    main section.rubrique:not(.full) article:nth-of-type(2n+1) {
        margin-left: 0 !important;
    }

    main section.rubrique article.highlight:nth-of-type(3n+1),
    main section.rubrique article + article.highlight:nth-of-type(3n+1) {
        /*margin-left: 0;*/
    }

    main section.rubrique article.highlight:nth-of-type(3n),
    main section.rubrique article + article.highlight:nth-of-type(3n) {
        /*margin-right: 0;*/
    }

    main section.rubrique:not(.full) article.highlight:nth-of-type(2n+1) {
        /*margin-left: -5px !important;*/
    }

    main section.rubrique:not(.full) article.summary.list:nth-of-type(2n+1) {
        clear: both;
    }

    main section.rubrique:not(.full) article:nth-of-type(2n) {
        margin-left: 30px !important;
    }

    main section.rubrique:not(.full) article + article.highlight:nth-of-type(2n),
    main section.rubrique:not(.full) article.highlight + article:nth-of-type(2n) {
        /*margin-left: 25px !important;
        margin-right: -5px !important;*/
    }

    main section.rubrique article.highlight + article.highlight:nth-of-type(2n) {
        /*margin-left: 20px !important;
        margin-right: -5px !important;*/
    }

    main section.rubrique:not(.full) article:not(.full) {
        width: 49% !important;
        width: calc(50% - 15px) !important;
    }

    main section.rubrique:not(.full) article.highlight:not(.full) {
        /*width: 49% !important;
        width: calc(50% - 5px) !important;*/
    }

    main section.rubrique:not(.full) article section.image {
        width: 100% !important;
        padding-bottom: 66.6666% !important;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1140px) {
    main:not(.commerce) section.rubrique:not(.full):not(.metiers) article:nth-of-type(3n+1) {
        margin-left: 0 !important;
    }
    main.commerce section.rubrique.metiers:not(.full) article:nth-of-type(2n+1) {
        margin-left: 0 !important;
    }

    main section.rubrique:not(.full) article.highlight:nth-of-type(3n+1) {
        /*margin-left: -5px !important;*/
    }

    main section.rubrique:not(.full) article:nth-of-type(3n) {
        margin-left: 30px !important;
    }

    main section.rubrique:not(.full) article + article.highlight:nth-of-type(3n),
    main section.rubrique:not(.full) article.highlight + article:nth-of-type(3n) {
        /*margin-left: 25px !important;*/
    }

    main section.rubrique article.highlight + article.highlight:nth-of-type(3n) {
        /*margin-left: 20px !important;*/
    }

    main:not(.commerce) section.rubrique:not(.full):not(.metiers) article:not(.full) {
        width: 32.5% !important;
        width: calc(33.3333333% - 20px) !important;
    }

    main.commerce section.rubrique.metiers:not(.full) article:not(.full) {
        width: 48.5% !important;
        width: calc(50% - 20px) !important;
    }

    main section.rubrique:not(.full) article.highlight:not(.full) {
        width: 32.5% !important;
        width: calc(33.3333333% - 10px) !important;
    }

    main section.rubrique:not(.full) article section.image {
        width: 100% !important;
        padding-bottom: 66.6666% !important;
    }
}

@media screen and (max-width: 649px) {
    main section.rubrique article,
    main section.rubrique article.annonce.summary.image_on_left {
        width: 100% !important;
        margin-left: 0 !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    main section.rubrique article.annonce.summary.highlight.image_on_top {
        /*width: 100% !important;
        margin-left: 0 !important;
        padding: 0 !important;*/
    }

    main section.rubrique article.annonce.summary.invisible {
        display: none;
    }

    main section.rubrique article section.image {
        width: 100% !important;
        padding-bottom: 66.6666% !important;
    }

    main section.rubrique > footer {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media print {
    main {
        min-width: 1140px;
    }
}

@media screen and (max-width: 768px) {
    main.index section.construction {
        display: none;
    }

    main.index section.transactions article,
    main.index section.immobiliere article {
        width: 100%;
    }

    main.index section.transactions article:first-of-type ul,
    main.index section.immobiliere article:first-of-type ul {
        margin-bottom: 0;
    }

    main.index section.transactions article:first-of-type ul hr:last-of-type,
    main.index section.immobiliere article:first-of-type ul hr:last-of-type {
        height: 0;
    }

    main.index div.presentation.darkblue {
        height: 699px;
    }

    main.index section.presentation.darkblue {
        margin-top: -699px;
    }

    main.index div.metiers.darkblue {
        height: 305px;
    }

    main.index section.metiers.darkblue {
        margin-top: -305px;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1140px) {
    main.index section.rubrique.transactions article,
    main.index section.rubrique.immobiliere article {
        width: 49% !important;
        width: calc(50% - 15px) !important;
    }
}

/* ###################
     LISTE D'ANNONCES
   ###################*/

main.recherche_biens {
    min-height: 780px;
    position: relative;
}

main.recherche_biens #sticky_map {
    position: absolute;
    top: 0;
    left: 0;
    right: 965px;
    min-height: 300px;
}

main.recherche_biens #map_search_results {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 100px;
    width: 100%;
}

main.recherche_biens #large_map {
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 28px;
    height: 28px;
    display: none;
    color: #666666;
    text-align: center;
    line-height: 18px;
    font-size: 32px;
    font-weight: bold;
    margin-right: 10px;
}

main.recherche_biens #map_search_results #large_map {
    display: block;
}

main.recherche_biens h2.search_extend {
    padding: 10px 0 10px 0;
    margin: 20px 0 0 auto;
    width: 945px;
    color: #0D85D2;
}

main.recherche_biens .search_alert {
    position: absolute;
}

main.recherche_biens .search_alert,
main.annonce .search_alert {
    left: 0;
    right: 0;
    bottom: 0;
    color: #FFFFFF;
    background-color: #0D85D2;
    background-image: url("/img/icon_alert.svg");
    background-repeat: no-repeat;
    background-position: 20px 20px;
    padding: 20px 10px 20px 80px;
}

main.recherche_biens .search_alert section,
main.annonce .search_alert section {
    width: auto;
    float: left;
    margin: 0 1em 10px 0;
}

main.recherche_biens .search_alert section > div,
main.annonce .search_alert section > div {
    font-weight: bold;
    font-size: 15px;
}

main.recherche_biens .search_alert form,
main.annonce .search_alert form {
    float: left;
    width: 270px;
}

main.recherche_biens .search_alert form input[type="email"],
main.annonce .search_alert form input[type="email"],
main.recherche_biens .search_alert form input[type="text"],
main.annonce .search_alert form input[type="text"] {
    width: 200px;
    height: 35px;
    border-radius: 5px 0 0 5px;
    border: none;
    padding: 5px 10px;
    vertical-align: top;
}

main.recherche_biens .search_alert form input[type="text"],
main.annonce .search_alert form input[type="text"] {
    border-radius: 5px;
    font-size: 11px;
    height: auto;
}

main.recherche_biens .search_alert form input[type="button"],
main.annonce .search_alert form input[type="button"],
main.recherche_biens .search_alert form input[type="submit"],
main.annonce .search_alert form input[type="submit"] {
    height: 35px;
    line-height: 35px;
    background-color: #FDCB27;
    border-radius: 0 5px 5px 0;
    border: none;
    margin: 0;
    display: inline;
    color: #4E4E4E;
}

main.recherche_biens .search_alert form input[type="submit"],
main.annonce .search_alert form input[type="submit"] {
    padding-left: 5px; padding-right: 5px;
}

main.recherche_biens .search_alert form input[type="button"],
main.annonce .search_alert form input[type="button"] {
    width: 33px;
    line-height: normal;
    background-image: url("/img/icon_mail_alert.svg");
    background-repeat: no-repeat;
    background-position: 9px 11px;
}

main.recherche_biens > header {
    width: 945px;
    margin: 20px 0 20px auto;
    float: none;
}

main.recherche_biens header.pagination,
main.recherche_biens footer.pagination {
    width: 945px;
    margin: 20px 0 20px auto;
    float: none;
}

@media screen and (max-width: 1700px) {
    main.recherche_biens .search_alert form {
        position: relative;
        left: 0;
    }

    main.recherche_biens:not(.small) #map_search_results {
        bottom: 131px;
    }
}

@media screen and (max-width: 1370px) {
    main.recherche_biens:not(.large) #sticky_map {
        right: 380px;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left {
        width: 360px; height: auto;
        min-height: 240px;
        overflow: auto;
        float: none;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left.highlight section.image {
        /*width: 100%;*/
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left.highlight section.text,
    main.recherche_biens:not(.large) article.annonce.summary.image_on_left section.text {
        width: 100%; height: auto;
        min-height: 240px;
        background-color: #D9E9F5;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left section.text section.bandeau:not(.right) {
        display: none;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left section.text p.commentaire {
        margin-bottom: 35px;
        height: auto;
        overflow: visible;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left footer {
        padding-left: 0;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left footer a.button + a.button {
        margin-top: 5px;
    }

    main.recherche_biens:not(.large) article.annonce.summary.image_on_left footer a.button.file {
        width: 220px;
    }

    main.recherche_biens:not(.large) > header,
    main.recherche_biens:not(.large) header.pagination,
    main.recherche_biens:not(.large) footer.pagination {
        width: 360px;
    }

    main.recherche_biens:not(.large) h2.search_extend {
        width: 360px;
    }
}

@media screen and (max-width: 945px) {
    main.recherche_biens #sticky_map {
        display: none;
    }

    main.recherche_biens > header,
    main.recherche_biens header.pagination,
    main.recherche_biens footer.pagination {
        width: 100% !important;
        margin: 20px auto 20px auto;
    }

    main.recherche_biens article.annonce.summary.image_on_left {
        margin: 0 auto 20px auto;
    }

    main.recherche_biens h2.search_extend {
        margin: 0 auto 20px auto;
    }
}

@media screen and (min-width: 1371px) {
    main.recherche_biens.small #sticky_map {
        right: 380px;
    }

    main.recherche_biens.small article.annonce.summary.image_on_left {
        width: 360px; height: auto;
        min-height: 240px;
        overflow: auto;
        float: none;
    }

    main.recherche_biens.small article.annonce.summary.image_on_left.highlight section.image {
        /*width: 100%;*/
    }

    main.recherche_biens.small article.annonce.summary.image_on_left.highlight section.text,
    main.recherche_biens.small article.annonce.summary.image_on_left section.text {
        width: 100%; height: auto;
        min-height: 240px;
        background-color: #D9E9F5;
        padding-bottom: 35px;
    }

    main.recherche_biens.small article.annonce.summary.image_on_left section.text section.bandeau:not(.right) {
        display: none;
    }

    main.recherche_biens.small article.annonce.summary.image_on_left section.text p.commentaire {
        margin-bottom: 35px;
        height: auto;
        overflow: visible;
    }

    main.recherche_biens.small > header,
    main.recherche_biens.small header.pagination,
    main.recherche_biens.small footer.pagination {
        width: 360px;
    }

    main.recherche_biens.small h2.search_extend {
        width: 360px;
    }
}

@media screen and (max-width: 1140px) {
    main.annonce .search_alert form {
        position: relative;
        left: 0;
    }
}

@media screen and (max-width: 945px) {
    main.recherche_biens h2.search_extend {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    main.annonce .search_alert {
        padding: 20px 0px 20px 45px;
        background-position: 2px 20px;
    }

    main.annonce .search_alert section {
        width: 270px;
        margin-right: 0;
    }
}

@media screen and (max-width: 360px) {
    article.annonce.summary.image_on_left {
        width: 100% !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    article.annonce.summary.image_on_left.highlight {
        /*width: 100%;
        padding-left: 0px;
        padding-right: 0px;*/
    }

    article.annonce.summary.image_on_left section.image {
        width: 100% !important;
        height: 0;
        padding-bottom: 66.6666% !important;
    }

    main.recherche_biens h2.search_extend {
        padding-left: 5px;
        padding-right: 5px;
    }
}

/* ###################
         OFFRES
   ###################*/

main.offres:not(.map) {
    max-width: 1140px;
    margin-top: 50px;
}

main.offres section.flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
}

main.offres #map_offres_results {
    height: 760px;
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
}

main.offres section.flex section.flex {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
    margin: 0 0 0 auto;
}

main.offres section.flex > article {
    flex-basis: calc(50% - 15px);
    min-width: 300px;
    width: auto;
    max-width: calc(50% - 15px);
    height: 365px;
    margin-left: 0;
    margin-bottom: 30px;
    padding: 20px 10px 0 10px;
    background-color: #D9E9F5;
    position: relative;
}

main.offres section.flex > article.list {
    padding: 20px 60px 0 60px;
}

main.offres section.flex > article p {
    font-size: 13px;
}

main.offres section.flex > article p a {
    color: #4E4E4E;
}

main.offres section.flex > article h1,
main.offres section.flex > article h2,
main.offres section.flex > article h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 1em;
}

main.offres section.flex > article h1 {
    color: #FFFFFF;
}

main.offres section.flex > article h2.h3 {
    color: #FFFFFF;
    text-transform: none;
}

main.offres section.flex > article h4,
main.offres section.flex > article h2 {
    color: #0D85D2;
}

main.offres section.flex > article:first-child {
    background-color: #0D85D2;
    color: #FFFFFF;
    overflow-y: auto;
}

main.offres section.flex > article footer {
    background-color: #0D85D2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 35px 0;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: bold;
}

main.offres article.list ul {
    list-style-type: '\279C';
}

@media screen and (max-width: 1230px) {
    main.offres #map_offres_results {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 30px;
    }

    main.offres section.flex section.flex {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media screen and (min-width: 1501px) {
    main.offres section.flex > article {
        padding: 20px 20px 0 20px;
    }
}

@media screen and (max-width: 600px) {
    main.offres #map_offres_results {
        height: 380px;
    }

    main.offres section.flex > article {
        flex-basis: 100%;
        max-width: 100%;
        margin-left: 5px;
        margin-right: 5px;
    }
}

/* ###################
         ANNONCE
   ###################*/

article.annonce.summary.image_on_left section.text h5.reference span,
article.annonce.summary.image_on_top section.details h4.reference span,
main.annonce section.summary section.reference span {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFFFFF;
    margin-right: 1em;
    padding: 3px 7px;
    vertical-align: top;
}

article.annonce.summary.image_on_left section.text h5.reference span,
article.annonce.summary.image_on_top section.details h4.reference span {
    font-size: 11px;
}

article.annonce.summary.image_on_left section.text h5.reference span.highlight,
article.annonce.summary.image_on_top section.details h4.reference span.highlight,
main.annonce section.summary section.reference span.highlight {
    background-color: #0D85D2;
}

article.annonce.summary.image_on_left section.text h5.reference span.new,
article.annonce.summary.image_on_top section.details h4.reference span.new,
main.annonce section.summary section.reference span.new {
    background-color: #FF4E4E;
}

#map_legend {
    background-color: #F5F5F5;
    padding-left: 6px;
    padding-right: 6px;
    display: none;
}

#map_search_results #map_legend {
    display: block;
}


/* ###################
      PUBLICATIONS
   ###################*/

main.publications.list article.list {
    width: 720px;
}

main.publications.search article.list,
main.publications.list article.list {
    width: 100%;
}

main.publications.search article.list hr,
main.publications.list article.list hr {
    width: 57%;
    margin-left: 36px;
}

main.publications.search article.list li,
main.publications.list article.list li {
    position: relative;
    min-height: 40px;
}

main.publications.search a.button,
main.publications.list a.button {
    position: absolute;
    top: 0;
    right: 0;
    color: #FFFFFF;
}


/* ###################
         ARTICLE
   ###################*/

main.article {
    max-width: 1140px;
    margin-top: 50px;
    overflow: auto;
}

main.article section.corps {
    text-align: justify;
    font-size: 1rem;
    clear: both;
}

main.article section.corps img {
    max-width: 100%;
    height: auto !important;
}

main.article section.corps img.gauche,
main.article section.corps img[style*="float:left"],
main.article section.corps img[style*="float: left"] {
    float: left;
    margin: 0 10px 10px 0 !important;
}

/*main.article section.corps img.gauche:first-child,
main.article section.corps img[style*="float:left"]:first-child,
main.article section.corps img[style*="float: left"]:first-child {
    margin-top: 0 !important;
}*/

main.article section.corps img.droite,
main.article section.corps img[style*="float:right"],
main.article section.corps img[style*="float: right"] {
    float: right;
    margin: 0 0 10px 10px !important;
}

/*main.article section.corps img.droite:first-child,
main.article section.corps img[style*="float:right"]:first-child,
main.article section.corps img[style*="float: right"]:first-child {
    margin-top: 0 !important;
}*/

main.article section.corps table {
    max-width: 100%;
}

main.article section.corps div.definition {
    color: #FFFFFF;
    position: absolute;
    border: 1px solid #FFFFFF;
    background: #0D85D2;
    width: 220px;
    padding: 5px;
    z-index: 2;
}

main.article section.corps section.diaporama {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

main.article section.corps section.diaporama > div {
    top: 0;
    height: 240px;
    position: absolute;
    white-space: nowrap;
    -moz-transition: left 300ms ease-out;
    -webkit-transition: left 300ms ease-out;
    transition: left 300ms ease-out;
}

main.article section.corps section.diaporama > div img {
    margin-right: 5px;
}

main.article section.corps section.diaporama nav {
    bottom: 0;
    width: 100%;
    height: 60px;
    position: absolute;
}

main.article section.corps section.diaporama nav > span,
main.article section.corps section.diaporama nav > a {
    position: absolute;
}

main.article section.corps section.diaporama nav > span {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 60px;
}

main.article section.corps section.diaporama nav > a {
    top: 10px;
}

main.article section.corps section.diaporama nav > a.button.prev {
    left: 0;
    padding-right: 0;
}

main.article section.corps section.diaporama nav > a.button.next {
    right: 0;
    padding-left: 0;
}

main.article section.corps .videodetector {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

main.article section.corps .videodetector iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

main.article article > nav {
    min-height: 40px;
    padding-right: 60px;
}

main.article > article {
    width: 730px;
    float: left;
    margin-bottom: 50px;
}

main.article > article.full {
    width: 100%;
}

main.article article > nav {
    background-color: #D9E9F5;
    margin-bottom: 40px;
    line-height: 40px;
    position: relative;
}

main.article article > nav span {
    background-color: #FDCB27;
    color: var(--bleu_fonce);
    padding: 0 14px 0 14px;
    font-size: 13px;
    display: inline-block;
    text-transform: uppercase;
    height: 40px;
}

main.article article > nav img {
    vertical-align: -3px;
    margin: 0 5px 0 15px;
}

main.article article > nav a {
    position: absolute;
    top: 7px;
    right: 75px;
}

main.article article > nav a + a {
    right: 15px;
}

main.article article > nav a img {
    height: 24px;
    vertical-align: 0;
}

main.article article header.pagination nav.sort,
main.article article footer.pagination nav.sort {
    position: static;
}

/*main.article article header.pagination span a.prev::after,
main.article article footer.pagination span a.prev::after,
main.article article header.pagination span a.next::before,
main.article article footer.pagination span a.next::before {
    content: '';
    padding: 0;
}*/

/*main.article article header.pagination span a.prev,
main.article article footer.pagination span a.prev,
main.article article header.pagination span a.next,
main.article article footer.pagination span a.next {
    font-size: 20px;
}*/

main.article article h1,
main section header h1 {
    color: #0D85D2;
    font-size: 30px;
    font-weight: normal;
}

main.article article h2,
main section header h2 {
    color: #0D85D2;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 40px;
}

main.annonce footer.share,
main.article article footer.share {
    margin: 20px auto 20px auto;
    text-align: right;
}

main.annonce footer.share img,
main.article article footer.share img {
    height: 35px;
    vertical-align: middle;
}

@media screen and (min-width: 1263px) {
    main.annonce footer.share,
    main.article article footer.share {
        display: none;
    }
}

main.article aside {
    width: 360px;
    float: right;
}

main.article aside a.button {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    background-image: url('/img/button_home_bg.svg');
    background-size: 24px auto;
    background-repeat: no-repeat;
    background-position: 22px 9px;
    padding-left: 56px;
    width: 100%;
}

main.article aside a.button:hover {
    background-position: 22px -23px;
}


main.article aside section.keywords div {
    font-size: 30px;
    color: #0D85D2;
    margin-bottom: 10px;
}

main.article aside section.keywords a {
    display: block;
    float: left;
    border: 1px solid #8BA0AF;
    color: #8BA0AF;
    padding: 5px 10px;
    margin: 0 5px 5px 0;
    font-size: 16px;
    text-decoration: none;
}

main.article aside section.keywords a:hover {
    border-color: #0D85D2;
}

main.article aside section.keywords a.active {
    color: #0D85D2;
}

main.article aside section.keywords span:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1140px) {
    main.article > article {
        width: 100%;
        float: none;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    main.article aside {
        width: 100%;
        float: none;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}


/* ###################
         CONTACT
   ###################*/

main.contact {
    max-width: 1140px;
    margin-top: 50px;
    margin-bottom: 50px;
    /*overflow: auto;*/
    align-items: flex-start;
}

main.contact section {
    width: 48%;
    margin-right: 2%;
}

main.contact section + section {
    margin-left: 2%;
    margin-right: 0;
}

main.contact section > header h3,
main.contact > header h3 {
    color: #0D85D2;
    text-align: left;
}

main.contact section > header h4,
main.contact > header h4 {
    text-align: left;
}

main.contact section.flex article {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 1em;
}

main.contact section.flex hr {
    width: 100%;
    height: 1px;
    background: #BFCFDB;
    border: none;
    margin: 5px auto 20px;
}

main.contact.confier section header {
    margin-bottom: 1em;
}

main.contact.confier section article h3 {
    color: #0D85D2;
    clear: both;
}

main.contact.confier section article dl + h3 {
    margin-top: 1em;
}

main.contact.confier form label {
    width: 150px;
    margin-bottom: 5px;
}

main.contact .map_element .pin {
    position: absolute;
    transform: translate(-65%, -97%);
}

main.contact .map_element .nom {
    color: #0D85D2;
    position: absolute;
    transform: translateX(-50%);
    text-shadow: 0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF,0 0 2px #FFF;
    z-index: 2;
}

main.contact .map_element .agence {
    display: none;
    transform: translate(-50%, 1.2em);
    position: absolute;
    background-color: var(--bleu_blanc);
    padding: 10px;
    border-radius: 5px;
    z-index: 3;
    width: max-content;
    min-width: 200px;
}

main.contact .map_element img:hover + div + .agence,
main.contact .map_element div:hover + .agence {
    display: block;
}

main.contact .contact_block {
    width: 28% !important;
}

main.contact .contact_block:target {
    position: relative;
}

main.contact .contact_block:target:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 50px;
    top: -10px;
    left: -20px;
    border: 4px solid var(--jaune);
    border-radius: 15px;
    clip-path: polygon(0 0, 75% 0, 75% 50%, 0 50%);
}

main.contact .contact_block:target:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 50px;
    bottom: -5px;
    right: -20px;
    border: 4px solid var(--bleu_fonce);
    border-radius: 15px;
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
}

main.contact .contact_block:target div:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 50px;
    bottom: -5px;
    right: -20px;
    border: 4px solid var(--jaune);
    border-radius: 15px;
    clip-path: polygon(25% 50%, 100% 50%, 100% 100%, 25% 100%);
}

main.contact.dispolog .map article {
    margin-top: -90px;
}

@media screen and (max-width: 768px) {
    main.contact section {
        width: 100%;
        margin-right: 0;
    }

    main.contact section + section {
        margin-left: 0;
    }

    main.contact.dispolog .map article {
        margin-top: 0;
    }

    main.contact .contact_block {
        width: 45% !important;
    }
}

@media screen and (max-width: 1140px) {
    main.contact section {
        padding-left: 5px;
        padding-right: 5px;
    }
}


/* ###################
         LEXIQUE
   ###################*/

main.lexique {
    margin-bottom: 50px;
}

main.lexique h3 {
    font-size: 15px;
    margin-top: 1em;
    margin-bottom: 15px;
}

main.lexique div.col {
    width: 33%;
    float: left;
}

/* ###################
     MENTIONS L�GALES et POLITIQUE DE CONFIDENTIALIT�
   ###################*/

main.mentions_legales,
main.politique_confidentialite {
    max-width: 1140px;
    margin-top: 50px;
    overflow: auto;
}

main.mentions_legales p,
main.politique_confidentialite p {
    margin: 1em 0;
}

main.mentions_legales ul,
main.mentions_legales ol,
main.politique_confidentialite ul,
main.politique_confidentialite ol {
    font-size: 15px;
}


/* ###################
     PLAN DU SITE
   ###################*/

main.plan {
    max-width: 1140px;
    margin-top: 50px;
    overflow: auto;
}

main.plan li + li {
    margin-top: 0.5em;
}

main.plan article {
    font-size: 15px;
}